import {
  GET_COMMERCE_PURCHASE,
  SET_PURCHASE_DELIVERY_NUMBER,
  SET_COMMERCE_PURCHASE_PROCESS,
  SET_COMMERCE_PURCHASE_CURRENT_PAGE,
  SET_COMMERCE_SEARCH_OBJ,
  SET_COMMERCE_PURCHASE_LIST,
  SET_COMMERCE_PURCHASE_MAX_PAGE,
  CHANGE_COMMERCE_PURCHASE_STATUS,
  SET_CHANGE_COMMERCE_PURCHASE_STATUS,
  SET_PURCHASE_DELIVERY_NUMBERS,
  ERROR_COMMERCE_PURCHASE,
  SET_COMMERCE_PURCHASE_INITIAL,
  CHANGE_CURRENT_PUSH_STATE_COMMERCE,
} from "../actions/types";

const initialState = {
  purchaseList: [],
  purchaseProcess: false,
  purchaseCount: 0,
  searchObj: {
    item_name: "",
    username: "",
    receiptId: "",
    search_status: "",
  },
  currentPage: 0,
  maxPage: 1,
  changeStatus: false,
  initial: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_PUSH_STATE_COMMERCE:
      return {
        ...state,
        purchaseCount: state.purchaseCount - action.payload.ids.length,
        purchaseList: [
          ...state.purchaseList
            .filter((item) => {
              if (action.payload.ids.includes(item._id)) {
                return false;
              } else {
                return true;
              }
            })
            .sort((a, b) => a.createdAt - b.createdAt),
          ...action.payload.moreItems,
        ],
      };

    case SET_COMMERCE_PURCHASE_INITIAL:
      return {
        ...state,
        initial: true,
      };
    case GET_COMMERCE_PURCHASE:
      return {
        ...state,
        purchaseList: action.payload.type ? action.payload.data : [...state.purchaseList, ...action.payload.data],
        purchaseCount: action.payload.count,
        initial: false,
        purchaseProcess: false,
      };

    case SET_PURCHASE_DELIVERY_NUMBER:
      const tempLDL = [...state.purchaseList];
      tempLDL.forEach((l) => {
        if (l._id == action.payload.purchaseId) {
          l.delivery_number = action.payload.delivery_number;
          if (l.order_status == "standby") {
            l.order_status = "processing";
          }
        }
      });
      return {
        ...state,
        purchaseProcess: true,
        purchaseList: tempLDL,
      };

    case SET_COMMERCE_PURCHASE_PROCESS:
      return {
        ...state,
        purchaseProcess: action.payload,
      };
    case SET_COMMERCE_PURCHASE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_COMMERCE_SEARCH_OBJ:
      return {
        ...state,
        searchObj: action.payload,
      };

    case SET_COMMERCE_PURCHASE_LIST:
      return {
        ...state,
        purchaseList: action.payload,
      };
    case SET_COMMERCE_PURCHASE_MAX_PAGE:
      return {
        ...state,
        maxPage: action.payload,
      };
    case CHANGE_COMMERCE_PURCHASE_STATUS:
      const tempL = [...state.purchaseList];
      tempL.map((l) => {
        if (action.payload.purchaseIds.includes(l._id)) {
          l.order_status = action.payload.status;
        }
      });

      return {
        ...state,
        changeStatus: true,
        purchaseList: tempL,
      };
    case SET_CHANGE_COMMERCE_PURCHASE_STATUS:
      return {
        ...state,
        changeStatus: false,
      };
    case SET_PURCHASE_DELIVERY_NUMBERS:
      return {
        ...state,
        purchaseList: action.payload.data,
        purchaseCount: action.payload.count,
      };
    case ERROR_COMMERCE_PURCHASE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
