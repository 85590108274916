import React from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/authActions";
import "../stylesheets/layoutBuilder.scss";

const { Header } = Layout;
const AppHeader = (props) => {
  const handleLogout = (e) => {
    e.preventDefault();
    props.logout();
  };

  return (
    <Header className="appHeader">
      <div
        className="menuContainer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="mainText" style={{ color: "rgb(209, 209, 209)" }}>
          단꿈아이 어플리케이션 관리자단 보드
        </div>
        <div className="logOut smallScreen">
          <a onClick={handleLogout} style={{ color: "rgb(209, 209, 209)" }}>
            로그아웃
          </a>
        </div>
      </div>
    </Header>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(AppHeader);
