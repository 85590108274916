import { actions } from "react-table";
import {
  BLOG_FAN_LOADING,
  BLOG_FAN_ERROR,
  GET_BLOG_FAN_POSTLIST,
  GET_BLOG_FAN_POST,
  CREATE_BLOG_FAN_POST,
  UPDATE_BLOG_FAN_POST,
  INIT_DETAIL_BLOG_FAN_POST,
  GET_BLOG_FAN_POST_COMMENT,
  ADD_BLOG_FAN_POST_COMMENT,
  EDIT_BLOG_FAN_POST_COMMENT,
  DELETE_BLOG_FAN_POST_COMMENT,
  ADD_BLOG_FAN_POST_STATUS,
  EDIT_BLOG_FAN_POST_STATUS,
  DELETE_BLOG_FAN_POST,
  DELETE_BLOG_FAN_POST_STATUS,
  DELETE_BLOG_FAN_POSTS,
  DELETE_BLOG_FAN_POSTS_STATUS,
  CHANGE_BLOG_FAN_POSTS_PERMISSION,
  CHANGE_BLOG_FAN_POSTS_PERMISSION_STATUS,
  SET_GET_BLOG_FAN_POSTINGS_STATUS,
  GET_TEST_BLOG_FAN_POSTLIST,
  END_GET_TEST_BLOG_FAN_POSTLIST_STATUS,
  GET_BLOG_FAN_POSTS_APP_USERS,
  END_GET_BLOG_FAN_POSTS_APP_USERS_STATUS,
  END_GET_BLOG_FAN_POSTLIST_STATUS,
  GET_BLOG_FAN_MONGO_POSTLIST,
  GET_FAN_BLOG_LIST_COUNT,
  SET_FAN_BLOG_LIST_COUNT,
  SEARCH_BLOG_FAN_POSTING_LIST,
  SET_SEARCH_BLOG_FAN_POSTING_STATUS,
  SET_BLOG_FAN_MONGO_POSTLIST,
} from "../actions/types";

const initialState = {
  blogFanPosting: null,
  blogFanLoading: false,
  blogFanPostings: [],
  blogFanDetailViewId: null,
  comments: [],
  addBlogFanPostStatus: false,
  editBlogFanPostStatus: false,
  deleteBlogFanPostStatus: false,
  deleteBlogFanPostsStatus: false,
  changeBlogFanPostsPermissionStatus: false,
  getBlogFanPostingsStatus: false,
  getTestBlogFanPostingsStatus: false,
  testBlogFanPostings: [],
  getBlogFanPostsAppUsersStatus: false,
  blogFanPostsAppUsers: [],
  blogMongoFanPostings: [],
  blogMongoFanPostsAppUsers: [],
  blogFanPostListCount: 0,
  searchPostingStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_FAN_POST:
      return {
        ...state,
        blogFanPosting: action.payload,
      };
    case BLOG_FAN_LOADING:
      return {
        ...state,
        blogFanLoading: true,
      };

    case GET_BLOG_FAN_POSTS_APP_USERS:
      return {
        ...state,
        getBlogFanPostsAppUsersStatus: true,
        blogFanPostsAppUsers: action.payload.firstMount
          ? action.payload.data
          : [...state.blogFanPostsAppUsers, ...action.payload.data],
      };
    case END_GET_BLOG_FAN_POSTS_APP_USERS_STATUS:
      return {
        ...state,
        getBlogFanPostsAppUsersStatus: false,
      };

    case GET_BLOG_FAN_POSTLIST:
      return {
        ...state,
        getBlogFanPostingsStatus: true,
        blogFanPostings: action.payload.firstMount
          ? action.payload.data
          : [...state.blogFanPostings, ...action.payload.data],
      };

    case END_GET_BLOG_FAN_POSTLIST_STATUS:
      return {
        ...state,
        getBlogFanPostingsStatus: false,
      };

    // case GET_BLOG_FAN_POSTLIST:
    //   return {
    //     ...state,
    //     blogFanLoading: false,
    //     getBlogFanPostingsStatus: false,
    //     blogFanPostings: action.payload,
    //   };

    case SET_GET_BLOG_FAN_POSTINGS_STATUS:
      return {
        ...state,
        getBlogFanPostingsStatus: action.payload,
      };

    case CREATE_BLOG_FAN_POST:
      return {
        ...state,
        addBlogFanPostStatus: true,
        blogFanLoading: true,
        blogFanPostings: [action.payload, ...state.blogFanPostings],
      };
    case ADD_BLOG_FAN_POST_STATUS:
      return {
        ...state,
        addBlogFanPostStatus: false,
      };
    case INIT_DETAIL_BLOG_FAN_POST:
      return {
        ...state,
        blogFanDetailViewId: action.payload,
      };

    case UPDATE_BLOG_FAN_POST:
      return {
        ...state,
        editBlogFanPostStatus: true,
        blogFanLoading: true,
        blogFanPostings: state.blogFanPostings.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    case EDIT_BLOG_FAN_POST_STATUS:
      return {
        ...state,
        editBlogFanPostStatus: false,
      };
    case DELETE_BLOG_FAN_POST:
      return {
        ...state,
        deleteBlogFanPostStatus: true,
        blogFanPostings: state.blogFanPostings.filter((posting) => {
          return posting["_id"] !== action.payload;
        }),
      };
    case DELETE_BLOG_FAN_POST_STATUS:
      return {
        ...state,
        deleteBlogFanPostStatus: false,
      };
    case GET_BLOG_FAN_POST_COMMENT:
      return {
        ...state,
        blogFanLoading: true,
        comments: action.payload,
      };
    case ADD_BLOG_FAN_POST_COMMENT:
      return {
        ...state,
        blogFanLoading: true,
        comments: [action.payload, ...state.comments],
      };
    case EDIT_BLOG_FAN_POST_COMMENT:
      return {
        ...state,
        blogFanLoading: true,
        comments: state.comments.map((cmt) => (cmt._id == action.payload._id ? action.payload : cmt)),
      };

    case DELETE_BLOG_FAN_POST_COMMENT:
      return {
        ...state,
        blogFanLoading: true,
        comments: state.comments.filter((cmt) => cmt._id != action.payload.commentId),
      };
    case DELETE_BLOG_FAN_POSTS:
      return {
        ...state,
        deleteBlogFanPostsStatus: true,
        blogFanPostings: state.blogFanPostings.filter((posting) => {
          return !action.payload.includes(posting["_id"]);
        }),
      };
    case DELETE_BLOG_FAN_POSTS_STATUS:
      return {
        ...state,
        deleteBlogFanPostsStatus: false,
      };
    case CHANGE_BLOG_FAN_POSTS_PERMISSION:
      return {
        ...state,
        changeBlogFanPostsPermissionStatus: true,
        blogFanPostings: state.blogFanPostings.map((posting) =>
          action.payload.hasOwnProperty(posting._id) ? action.payload[posting._id] : posting,
        ),
      };
    case CHANGE_BLOG_FAN_POSTS_PERMISSION_STATUS:
      return {
        ...state,
        changeBlogFanPostsPermissionStatus: false,
      };
    case GET_BLOG_FAN_MONGO_POSTLIST:
      return {
        ...state,
        blogMongoFanPostings: action.payload,
        searchPostingStatus: false,
        getBlogFanPostingsStatus: false,
      };
    case GET_FAN_BLOG_LIST_COUNT:
      return {
        ...state,
        blogFanPostListCount: action.payload,
      };
    case SET_FAN_BLOG_LIST_COUNT:
      return {
        ...state,
        blogFanPostListCount: action.payload,
      };
    case SEARCH_BLOG_FAN_POSTING_LIST:
      return {
        ...state,
        blogFanPostListCount: action.payload.count,
        blogMongoFanPostings: action.payload.data,
        searchPostingStatus: true,
        getBlogFanPostingsStatus: false,
      };
    case SET_SEARCH_BLOG_FAN_POSTING_STATUS:
      return {
        ...state,
        searchPostingStatus: action.payload,
      };
    case SET_BLOG_FAN_MONGO_POSTLIST:
      return {
        ...state,
        blogMongoFanPostings: action.payload,
      };

    default:
      return state;
  }
}
