import React from "react";
import { Spin } from "antd";
import "../stylesheets/loading.scss";

function Loading() {
  return (
    <div className="loadingWrap">
      <Spin></Spin>
    </div>
  );
}
export default Loading;
