import {
  MAP_LOADING,
  GET_MAP_POSTLIST,
  CREATE_MAP_POST,
  UPDATE_MAP_POST,
  INIT_DETAIL_MAP_POST,
  GET_MAP_POST_COMMENT,
  ADD_MAP_POST_COMMENT,
  EDIT_MAP_POST_COMMENT,
  GET_MAP_POST_COMMENT_QUESTION,
  ADD_MAP_POST_COMMENT_QUESTION,
  EDIT_MAP_POST_COMMENT_QUESTION,
  DELETE_MAP_POST_COMMENT,
  DELETE_MAP_POST_COMMENT_QUESTION,
  ADD_MAP_POST_STATUS,
  EDIT_MAP_POST_STATUS,
  DELETE_MAP_POST,
  DELETE_MAP_POST_STATUS,
  DELETE_MAP_POSTS,
  DELETE_MAP_POSTS_STATUS,
  CHANGE_MAP_POSTS_PERMISSION,
  CHANGE_MAP_POSTS_PERMISSION_STATUS,
  SET_GET_MAP_POSTINGS_STATUS,
} from "../actions/types";

const initialState = {
  mapLoading: false,
  mapPostings: [],
  mapDetailViewId: null,
  commentQuestionViewId: null,
  comments: [],
  commentQuestions: [],
  openQuestionModal: false,
  addMapPostStatus: false,
  editMapPostStatus: false,
  deleteMapPostStatus: false,
  deleteMapPostsStatus: false,
  changeMapPostsPermissionStatus: false,
  getMapPostingsStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MAP_LOADING:
      return {
        ...state,
        mapLoading: false,
      };

    case GET_MAP_POSTLIST:
      return {
        ...state,
        mapLoading: true,
        getMapPostingsStatus: false,
        mapPostings: action.payload,
      };
    case SET_GET_MAP_POSTINGS_STATUS:
      return {
        ...state,
        getMapPostingsStatus: action.payload,
      };

    case CREATE_MAP_POST:
      return {
        ...state,
        addMapPostStatus: true,
        mapLoading: true,
        mapPostings: [action.payload, ...state.mapPostings],
      };
    case ADD_MAP_POST_STATUS:
      return {
        ...state,
        addMapPostStatus: false,
      };
    case INIT_DETAIL_MAP_POST:
      return {
        ...state,
        mapDetailViewId: action.payload,
      };
    case UPDATE_MAP_POST:
      return {
        ...state,
        editMapPostStatus: true,
        mapLoading: true,
        mapPostings: state.mapPostings.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case EDIT_MAP_POST_STATUS:
      return {
        ...state,
        editMapPostStatus: false,
      };
    case DELETE_MAP_POST:
      return {
        ...state,
        deleteMapPostStatus: true,
        mapPostings: state.mapPostings.filter((posting) => {
          return posting["_id"] !== action.payload;
        }),
      };
    case DELETE_MAP_POST_STATUS:
      return {
        ...state,
        deleteMapPostStatus: false,
      };
    case GET_MAP_POST_COMMENT:
      return {
        ...state,
        mapLoading: true,
        comments: action.payload,
      };
    case ADD_MAP_POST_COMMENT:
      return {
        ...state,
        mapLoading: true,
        comments: [action.payload, ...state.comments],
      };
    case EDIT_MAP_POST_COMMENT:
      return {
        ...state,
        mapLoading: true,
        comments: state.comments.map((cmt) =>
          cmt._id == action.payload._id ? action.payload : cmt
        ),
      };
    case GET_MAP_POST_COMMENT_QUESTION:
      return {
        ...state,
        mapLoading: true,
        commentQuestionViewId: action.payload.selectedCommentId,
        commentQuestions: action.payload.data,
        openQuestionModal: action.payload.openModal,
      };
    case ADD_MAP_POST_COMMENT_QUESTION:
      return {
        ...state,
        mapLoading: true,
        comments: state.comments.map((cmt) =>
          cmt._id == state.commentQuestionViewId
            ? { ...cmt, comments: [...cmt.comments, action.payload._id] }
            : cmt
        ),
        commentQuestions: [action.payload, ...state.commentQuestions],
      };
    case EDIT_MAP_POST_COMMENT_QUESTION:
      return {
        ...state,
        mapLoading: true,
        commentQuestions: state.commentQuestions.map((cmt) =>
          cmt._id == action.payload._id ? action.payload : cmt
        ),
      };
    case DELETE_MAP_POST_COMMENT:
      return {
        ...state,
        mapLoading: true,
        comments: state.comments.filter(
          (cmt) => cmt._id != action.payload.commentId
        ),
      };
    case DELETE_MAP_POST_COMMENT_QUESTION:
      return {
        ...state,
        mapLoading: true,
        comments: state.comments.map((cmt) =>
          cmt._id == action.payload.commentId
            ? {
                ...cmt,
                comments: cmt.comments.filter(
                  (comment) => comment != action.payload.questionId
                ),
              }
            : cmt
        ),
        commentQuestions: state.commentQuestions.filter(
          (question) => question._id != action.payload.questionId
        ),
      };
    case DELETE_MAP_POSTS:
      return {
        ...state,
        deleteMapPostsStatus: true,
        mapPostings: state.mapPostings.filter((posting) => {
          return !action.payload.includes(posting["_id"]);
        }),
      };
    case DELETE_MAP_POSTS_STATUS:
      return {
        ...state,
        deleteMapPostsStatus: false,
      };
    case CHANGE_MAP_POSTS_PERMISSION:
      return {
        ...state,
        changeMapPostsPermissionStatus: true,
        mapPostings: state.mapPostings.map((mapPosting) =>
          action.payload.hasOwnProperty(mapPosting._id)
            ? action.payload[mapPosting._id]
            : mapPosting
        ),
      };
    case CHANGE_MAP_POSTS_PERMISSION_STATUS:
      return {
        ...state,
        changeMapPostsPermissionStatus: false,
      };
    default:
      return state;
  }
}
