import {
  SET_QANDA_LIST,
  UPDATE_QANDA_LIST,
  CHANGE_QANDA_STATUS,
  CHANGE_QANDA_STATUS_STATE,
  DELETE_QANDA,
  DELETE_QANDAS,
  DELETE_QANDA_STATUS,
  DELETE_QANDAS_STATUS,
  SET_QANDA_PROCESS,
  SET_QANDA_CURRENT_PAGE,
  SET_QANDA_SEARCH_OBJ,
  SET_QANDA_MAX_PAGE,
  ADD_QANDA_COMMENT_STATUS,
  EDIT_QANDA_COMMENT,
  EDIT_QANDA_COMMENT_STATUS,
  DELETE_QANDA_COMMENT,
  CHANGE_CURRENT_PUSH_STATE_QA,
} from "../actions/types";

const initialState = {
  qAndAList: [],
  qAndAProcess: false,
  qAndACount: 0,
  searchObj: {
    qaType: 4,
    qaBody: "",
    qaUser: "",
    qaStatus: "all",
    qaAdmin: "",
  },
  currentPage: 0,
  maxPage: 50,
  changeStatus: false,
  initial: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_PUSH_STATE_QA:
      return {
        ...state,
        qAndACount: state.qAndACount - action.payload.ids.length + action.payload.moreItems.length,
        qAndAList: [
          ...state.qAndAList
            .filter((item) => {
              if (action.payload.ids.includes(item._id)) {
                return false;
              } else {
                return true;
              }
            })
            .sort((a, b) => a.createdAt - b.createdAt),
          ...action.payload.moreItems,
        ],
      };
    case SET_QANDA_PROCESS:
      return {
        ...state,
        qAndAProcess: action.payload,
      };

    case SET_QANDA_LIST:
      return {
        ...state,
        qAndAList: action.payload.type ? action.payload.data : [...state.qAndAList, ...action.payload.data],
        qAndACount: action.payload.count,
        initial: false,
        qAndAProcess: false,
      };
    case UPDATE_QANDA_LIST:
      return {
        ...state,
        addQAndACommentStatus: true,
        qAndAList: state.qAndAList.map((q) => (q._id == action.payload._id ? action.payload : q)),
      };
    case SET_QANDA_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_QANDA_SEARCH_OBJ:
      return {
        ...state,
        searchObj: action.payload,
      };
    case SET_QANDA_MAX_PAGE:
      return {
        ...state,
        maxPage: action.payload,
      };

    case EDIT_QANDA_COMMENT:
      const tempQ = [...state.qAndAList].find((l) => l._id == action.payload.qAndAId);
      tempQ.comments.forEach((c) => {
        if (c._id == action.payload._id) {
          c.content = action.payload.content;
        }
      });
      return {
        ...state,
        editQAndACommentStatus: true,
        qAndAList: state.qAndAList.map((q) => (q._id == tempQ._id ? tempQ : q)),
      };
    case EDIT_QANDA_COMMENT_STATUS:
      return {
        ...state,
        editQAndACommentStatus: false,
      };

    case ADD_QANDA_COMMENT_STATUS:
      return {
        ...state,
        addQAndACommentStatus: false,
      };

    case DELETE_QANDA_COMMENT:
      const tempQD = [...state.qAndAList].find((l) => l._id == action.payload.qAndAId);
      tempQD.comments = tempQD.comments.filter((c) => c._id != action.payload.commentId);
      return {
        ...state,
        qAndAList: state.qAndAList.map((q) => (q._id == tempQD._id ? tempQD : q)),
      };

    case CHANGE_QANDA_STATUS:
      const tempL = [...state.qAndAList];
      tempL.map((l) => {
        if (action.payload.qAndAIds.includes(l._id)) {
          l.status = action.payload.status;
        }
      });
      return {
        ...state,
        changQAndAStatusState: true,
        qAndAList: tempL,
      };
    case CHANGE_QANDA_STATUS_STATE:
      return {
        ...state,
        changQAndAStatusState: false,
      };

    case DELETE_QANDA:
      let tempQDL = [
        ...state.qAndAList.filter((l) => l["_id"] !== action.payload.deletedId),
        ...action.payload.afterItem,
      ];
      tempQDL = tempQDL.sort((a, b) => a.createdAt - b.createdAt);
      return {
        ...state,
        deleteQAndAStatus: true,
        qAndAList: tempQDL,
      };
    case DELETE_QANDAS:
      let tempQDLA = [
        ...state.qAndAList.filter((l) => !action.payload.deletedIds.includes(l["_id"])),
        ...action.payload.afterItem,
      ];
      tempQDLA = tempQDLA.sort((a, b) => a.createdAt - b.createdAt);

      return {
        ...state,
        deleteQAndAsStatus: true,
        qAndAList: tempQDLA,
      };

    case DELETE_QANDA_STATUS:
      return {
        ...state,
        deleteQAndAStatus: false,
      };

    case DELETE_QANDAS_STATUS:
      return {
        ...state,
        deleteQAndAsStatus: false,
      };

    default:
      return state;
  }
}
