import {
  GET_ALL_POSTINGS_WITH_DATE,
  SET_ALL_POSTINGS_PAGE,
  SET_CHOSEN_DATE,
  GET_CUMULATIVE_VIDEO_DATA_WITH_DATE,
  GET_POPULAR_VIDEO_DATA_WITH_DATE,
  GET_TOP_POPULAR_POSTING_DATA_WITH_DATE,
  GET_POPULAR_BLOG_FAN_POSTS_DATA_WITH_DATE,
  SET_POPULAR_BLOG_FAN_POSTS_PAGE,
  GET_POPULAR_MAP_POSTS_DATA_WITH_DATE,
  SET_POPULAR_MAP_POSTS_PAGE,
  GET_CONTENT_DASHBOARD_DOWNLOAD_FILE,
  GET_ALL_COMMENTS_WITH_DATE,
  SET_ALL_COMMENTS_PAGE,
} from "../actions/types";

const initialState = {
  chosenDate: null,

  allPostingsStatus: "initial",
  allPostingsWithDate: [],
  allPostingsPage: 0,
  allPostingsTotalCount: null,

  cumulativeVideoDataWithDate: null,

  topThreePopularVideoDataWithDate: [],
  popularVideoDataWithDate: [],

  topPopularPostingDataWithDate: {},

  popularBlogFanPostsStatus: "initial",
  popularBlogFanPostsWithDate: [],
  popularBlogFanPostsPage: 0,
  popularBlogFanPostsTotalCount: null,

  popularMapPostsStatus: "initial",
  popularMapPostsWithDate: [],
  popularMapPostsPage: 0,
  popularMapPostsTotalCount: null,

  contentDashboardDownloadFile: {},
  allCommentsWithDate: [],
  allCommentsPage: 0,
  allCommentsTotalCount: null,
  // endDate: null,
  chosenDate: null,
  allCommentsStatus : "initial"
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_POSTINGS_WITH_DATE:
      return {
        ...state,
        allPostingsWithDate:
          action.payload.type == "initial"
            ? action.payload.data
            : [...state.allPostingsWithDate, ...action.payload.data],
        allPostingsStatus:
          action.payload.type == "initial" ? "initial" : "not_initial",
        // endDate: action.payload.endDate,
        allPostingsPage: action.payload.currentPage,
        allPostingsTotalCount:
          action.payload.type == "initial"
            ? action.payload.totalCount
            : state.allPostingsTotalCount,
      };
    case SET_ALL_POSTINGS_PAGE:
      return {
        ...state,
        allPostingsPage: action.payload,
      };
    case SET_CHOSEN_DATE:
      return {
        ...state,
        chosenDate: action.payload,
      };
    case GET_CUMULATIVE_VIDEO_DATA_WITH_DATE:
      return {
        ...state,
        cumulativeVideoDataWithDate: action.payload,
      };

    case GET_POPULAR_VIDEO_DATA_WITH_DATE:
      let tempData = { ...state };
      if (action.payload.type == "initial") {
        tempData.topThreePopularVideoDataWithDate = action.payload.data;
      } else {
        tempData.popularVideoDataWithDate = action.payload.data;
      }
      return tempData;

    case GET_TOP_POPULAR_POSTING_DATA_WITH_DATE:
      return {
        ...state,
        topPopularPostingDataWithDate: action.payload,
      };

    case GET_POPULAR_BLOG_FAN_POSTS_DATA_WITH_DATE:
      return {
        ...state,
        popularBlogFanPostsWithDate:
          action.payload.type == "initial"
            ? action.payload.data
            : [...state.popularBlogFanPostsWithDate, ...action.payload.data],
        popularBlogFanPostsStatus:
          action.payload.type == "initial" ? "initial" : "not_initial",

        popularBlogFanPostsPage: action.payload.currentPage,
        popularBlogFanPostsTotalCount:
          action.payload.type == "initial"
            ? action.payload.totalCount
            : state.popularBlogFanPostsTotalCount,
      };
    case SET_POPULAR_BLOG_FAN_POSTS_PAGE:
      return {
        ...state,
        popularBlogFanPostsPage: action.payload,
      };
    case GET_POPULAR_MAP_POSTS_DATA_WITH_DATE:
      return {
        ...state,
        popularMapPostsWithDate:
          action.payload.type == "initial"
            ? action.payload.data
            : [...state.popularMapPostsWithDate, ...action.payload.data],
        popularMapPostsStatus:
          action.payload.type == "initial" ? "initial" : "not_initial",

        popularMapPostsPage: action.payload.currentPage,
        popularMapPostsTotalCount:
          action.payload.type == "initial"
            ? action.payload.totalCount
            : state.popularMapPostsTotalCount,
      };
    case SET_POPULAR_MAP_POSTS_PAGE:
      return {
        ...state,
        popularMapPostsPage: action.payload,
      };
    case GET_CONTENT_DASHBOARD_DOWNLOAD_FILE:
      return {
        ...state,
        contentDashboardDownloadFile: action.payload,
      };
    case GET_ALL_COMMENTS_WITH_DATE:
      return {
        ...state,
        allCommentsWithDate:
          action.payload.type == "initial"
            ? action.payload.data
            : [...state.allCommentsWithDate, ...action.payload.data],
        allCommentsStatus : action.payload.type === "initial" ?
          "initial" : "not_initial",
        allCommentsPage: action.payload.currentPage,
        allCommentsTotalCount:
          action.payload.type == "initial"
            ? action.payload.totalCount
            : state.allCommentsTotalCount,
      };
    case SET_ALL_COMMENTS_PAGE:
      return {
        ...state,
        allCommentsPage: action.payload,
      };

    default:
      return state;
  }
}
