import {
  SET_PUSH_NOTIFICATION_1,
  GET_PUSH_NOTIFICATION_1,
  CONFIRM_NEW_ONES,
  SET_SOCKET,
  SET_SOCKET_ID,
  PUSH_NOTIFICATION_1_STATUS,
  SET_CONFIRM_NEW_ONES_STATUS,
} from "../actions/types";

const initialState = {
  pushNotification1: { report: [], q_and_a: [], purchase: [], commerce_purchase: [] },
  socket: null,
  // socketId: [],
  pushNotification1Status: false,
  confirmNewOnesStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PUSH_NOTIFICATION_1:
      return {
        ...state,
        pushNotification1: action.payload,
      };
    case SET_PUSH_NOTIFICATION_1:
      return {
        ...state,
        pushNotification1: {
          ...state.pushNotification1,
          [action.payload.item_type]: [...state.pushNotification1[action.payload.item_type], action.payload.item_id],
        },
      };
    case SET_CONFIRM_NEW_ONES_STATUS:
      return {
        ...state,
        confirmNewOnesStatus: action.payload,
      };
    case CONFIRM_NEW_ONES:
      return {
        ...state,
        confirmNewOnesStatus: true,
        pushNotification1: {
          ...state.pushNotification1,
          [action.payload.type]: state.pushNotification1[action.payload.type].filter((item) => {
            if (action.payload.ids.includes(item)) {
              return false;
            } else {
              return true;
            }
          }),
        },
      };
    case SET_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    case SET_SOCKET_ID:
      localStorage.setItem("socketId", action.payload);
      return {
        ...state,
        // socketId: [...state.socketId, action.payload],
      };
    case PUSH_NOTIFICATION_1_STATUS:
      return {
        ...state,
        pushNotification1Status: action.payload,
      };
    default:
      return state;
  }
}
