import { convertToReadableDate } from "../../functions/index";

import {
  VIDEO_LOADING,
  GET_VIDEO_CATEGORIES,
  CREATE_VIDEO_CATEGORY,
  UPDATE_VIDEO_CATEGORY1,
  UPDATE_VIDEO_CATEGORY2,
  GET_VIDEO_POSTLIST,
  CREATE_VIDEO_POST,
  UPDATE_VIDEO_POST,
  INIT_DETAIL_VIDEO_POST,
  GET_VIDEO_POST_COMMENT,
  ADD_VIDEO_POST_COMMENT,
  EDIT_VIDEO_POST_COMMENT,
  GET_VIDEO_POST_COMMENT_QUESTION,
  ADD_VIDEO_POST_COMMENT_QUESTION,
  EDIT_VIDEO_POST_COMMENT_QUESTION,
  DELETE_VIDEO_POST_COMMENT,
  DELETE_VIDEO_POST_COMMENT_QUESTION,
  SELECT_PRODUCT,
  SELECT_COMMERCE_PRODUCT,
  DELETE_VIDEO_POST,
  DELETE_VIDEO_POST_STATUS,
  GET_VIDEO_TOKEN,
  GET_SORT_VIDEO_LIST,
  UPDATE_SORT_VIDEO_LIST,
  SET_SORT_VIDEO_STATUS,
} from "../actions/types";

const initialState = {
  videoLoading: false,
  categories: [],
  videoPostings: [],
  videoDetailViewId: null,
  commentQuestionViewId: null,
  comments: [],
  commentQuestions: [],
  openQuestionModal: false,
  selectedProductId: [],
  selectedCommerceProductId: [],
  deleteVideoPostStatus: false,
  videoToken: localStorage.getItem("video_token"),
  videoTokenExpiryDate: localStorage.getItem("video_token_expiry_date"),
  videoList: [],
  videoSortListStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VIDEO_LOADING:
      return {
        ...state,
        videoLoading: false,
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProductId: action.payload,
      };
    case SELECT_COMMERCE_PRODUCT:
      return {
        ...state,
        selectedCommerceProductId: action.payload,
      };

    case GET_VIDEO_CATEGORIES:
      return {
        ...state,
        videoLoading: true,
        categories: action.payload,
      };
    case CREATE_VIDEO_CATEGORY:
      return {
        ...state,
        videoLoading: true,
        categories: action.payload,
      };
    case UPDATE_VIDEO_CATEGORY1:
      return {
        ...state,
        videoLoading: true,
        categories: action.payload,
      };
    case UPDATE_VIDEO_CATEGORY2:
      return {
        ...state,
        videoLoading: true,
        categories: action.payload,
      };

    case GET_VIDEO_TOKEN:
      localStorage.setItem("video_token_expiry_date", action.payload.videoTokenExpiryDate);
      localStorage.setItem("video_token", action.payload.videoToken);
      return {
        ...state,
        videoToken: action.payload.videoToken,
        videoTokenExpiryDate: action.payload.videoTokenExpiryDate,
      };
    case GET_VIDEO_POSTLIST:
      return {
        ...state,
        videoLoading: true,
        videoPostings: action.payload,
      };
    case CREATE_VIDEO_POST:
      return {
        ...state,
        videoLoading: true,
        videoPostings: [action.payload, ...state.videoPostings],
      };

    case INIT_DETAIL_VIDEO_POST:
      return {
        ...state,
        videoDetailViewId: action.payload,
      };

    case UPDATE_VIDEO_POST:
      return {
        ...state,
        videoLoading: true,
        videoPostings: state.videoPostings.map((item) => (item._id === action.payload._id ? action.payload : item)),
        selectedProductId: action.payload.product_list,
      };
    case DELETE_VIDEO_POST:
      return {
        ...state,
        deleteVideoPostStatus: true,
        videoPostings: state.videoPostings.filter((posting) => {
          return posting["_id"] !== action.payload;
        }),
      };
    case DELETE_VIDEO_POST_STATUS:
      return {
        ...state,
        deleteVideoPostStatus: false,
      };

    case GET_VIDEO_POST_COMMENT:
      return {
        ...state,
        videoLoading: true,
        comments: action.payload,
      };
    case ADD_VIDEO_POST_COMMENT:
      return {
        ...state,
        videoLoading: true,
        comments: [action.payload, ...state.comments],
      };
    case EDIT_VIDEO_POST_COMMENT:
      return {
        ...state,
        videoLoading: true,
        comments: state.comments.map((cmt) => (cmt._id == action.payload._id ? action.payload : cmt)),
      };

    case GET_VIDEO_POST_COMMENT_QUESTION:
      return {
        ...state,
        videoLoading: true,
        commentQuestionViewId: action.payload.selectedCommentId,
        commentQuestions: action.payload.data,
        openQuestionModal: action.payload.openModal,
      };
    case ADD_VIDEO_POST_COMMENT_QUESTION:
      return {
        ...state,
        videoLoading: true,
        comments: state.comments.map((cmt) =>
          cmt._id == state.commentQuestionViewId ? { ...cmt, comments: [...cmt.comments, action.payload._id] } : cmt,
        ),
        commentQuestions: [action.payload, ...state.commentQuestions],
      };
    case EDIT_VIDEO_POST_COMMENT_QUESTION:
      return {
        ...state,
        videoLoading: true,
        commentQuestions: state.commentQuestions.map((cmt) => (cmt._id == action.payload._id ? action.payload : cmt)),
      };

    case DELETE_VIDEO_POST_COMMENT:
      return {
        ...state,
        videoLoading: true,
        comments: state.comments.filter((cmt) => cmt._id != action.payload.commentId),
      };

    case DELETE_VIDEO_POST_COMMENT_QUESTION:
      return {
        ...state,
        videoLoading: true,
        comments: state.comments.map((cmt) =>
          cmt._id == action.payload.commentId
            ? {
                ...cmt,
                comments: cmt.comments.filter((comment) => comment != action.payload.questionId),
              }
            : cmt,
        ),
        commentQuestions: state.commentQuestions.filter((question) => question._id != action.payload.questionId),
      };
    case GET_SORT_VIDEO_LIST:
      for (let i in action.payload) {
        action.payload[i].createdAt = convertToReadableDate(0, action.payload[i].createdAt);
        action.payload[i].updatedAt = convertToReadableDate(0, action.payload[i].updatedAt);
      }
      return {
        ...state,
        videoList: action.payload,
      };
    case UPDATE_SORT_VIDEO_LIST:
      return {
        ...state,
        videoSortListStatus: action.payload,
      };
    case SET_SORT_VIDEO_STATUS:
      return {
        ...state,
        videoSortListStatus: false,
      };
    default:
      return state;
  }
}
