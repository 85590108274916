import {
  BLOG_LOADING,
  SET_GET_BLOG_POSTINGS_STATUS,
  GET_BLOG_POSTLIST,
  CREATE_BLOG_POST,
  UPDATE_BLOG_POST,
  INIT_DETAIL_BLOG_POST,
  GET_BLOG_POST_COMMENT,
  ADD_BLOG_POST_COMMENT,
  EDIT_BLOG_POST_COMMENT,
  DELETE_BLOG_POST_COMMENT,
  ADD_BLOG_POST_STATUS,
  EDIT_BLOG_POST_STATUS,
  DELETE_BLOG_POST,
  DELETE_BLOG_POST_STATUS,
  GET_TEMP_DETAIL,
} from "../actions/types";

const initialState = {
  blogLoading: false,
  blogPostings: [],
  blogDetailViewId: null,
  comments: [],
  addBlogPostStatus: false,
  editBlogPostStatus: false,
  deleteBlogPostStatus: false,
  getBlogPostingsStatus: false,
  tempDetail : [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BLOG_LOADING:
      return {
        ...state,
        blogLoading: false,
      };

    case GET_BLOG_POSTLIST:
      return {
        ...state,
        blogLoading: true,
        getBlogPostingsStatus: false,
        blogPostings: action.payload,
      };
    case SET_GET_BLOG_POSTINGS_STATUS:
      return {
        ...state,
        getBlogPostingsStatus: action.payload,
      };

    case CREATE_BLOG_POST:
      return {
        ...state,
        addBlogPostStatus: true,
        blogLoading: true,
        blogPostings: [action.payload, ...state.blogPostings],
      };
    case ADD_BLOG_POST_STATUS:
      return {
        ...state,
        addBlogPostStatus: false,
      };
    case INIT_DETAIL_BLOG_POST:
      return {
        ...state,
        blogDetailViewId: action.payload,
      };
    case UPDATE_BLOG_POST:
      return {
        ...state,
        editBlogPostStatus: true,
        blogLoading: true,
        blogPostings: state.blogPostings.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case EDIT_BLOG_POST_STATUS:
      return {
        ...state,
        editBlogPostStatus: false,
      };
    case DELETE_BLOG_POST:
      return {
        ...state,
        deleteBlogPostStatus: true,
        blogPostings: state.blogPostings.filter((posting) => {
          return posting["_id"] !== action.payload;
        }),
      };
    case DELETE_BLOG_POST_STATUS:
      return {
        ...state,
        deleteBlogPostStatus: false,
      };

    case GET_BLOG_POST_COMMENT:
      return {
        ...state,
        blogLoading: true,
        comments: action.payload,
      };
    case ADD_BLOG_POST_COMMENT:
      return {
        ...state,
        blogLoading: true,
        comments: [action.payload, ...state.comments],
      };
    case EDIT_BLOG_POST_COMMENT:
      return {
        ...state,
        blogLoading: true,
        comments: state.comments.map((cmt) =>
          cmt._id == action.payload._id ? action.payload : cmt
        ),
      };

    case DELETE_BLOG_POST_COMMENT:
      return {
        ...state,
        blogLoading: true,
        comments: state.comments.filter(
          (cmt) => cmt._id != action.payload.commentId
        ),
      };

    case GET_TEMP_DETAIL :
      return {
        ...state ,
        tempDetail : action.payload
      }

    default:
      return state;
  }
}
