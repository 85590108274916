import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  INIT_APPLICATION,
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_FAIL_FIREBASE,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_FAIL_FIREBASE,
} from "./types";

export const initApplication = () => async (dispatch, getState) => {
  dispatch({ type: INIT_APPLICATION });
};
export const login =
  ({ email, password }) =>
  async (dispatch, getState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      email: email,
      password: password,
    });
    axios
      .post("/node_server/api/admin/adminauth/login", body, config)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err.response.data.message,
        });
      });
  };

export const refreshLogin = (token) => async (dispatch, getState) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  axios
    .get("/node_server/api/admin/adminauth/refresh", config)
    .then((res) => {
      console.log("Successfully relogged in");
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log("Failed to relog in");
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
    });
};

export const logout = () => async (dispatch, getState) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log("Successfully logged out");
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      console.log("err", err);
      dispatch({
        type: LOGOUT_FAIL,
      });
    });
};
