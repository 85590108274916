import {
  GET_CHAT_NOVEL,
  GET_CHAT_NOVEL_EPIC,
  CHAT_NOVEL_DETAIL,
  DELETE_CHAT_NOVEL_EPIC_COMMENT,
  UPDATE_CHAT_NOVEL_EPIC,
  CHAT_NOVEL_ERROR,
  CHAT_NOVEL_STATE,
  GET_EPIC_DOWNLOAD_FILE,
} from '../actions/types'

const initialState = {
  chatNovels: [],
  chatNovelEpics: [],
  chatNovelError: "",
  chatNovelState: false,
  chatNovelDetail : {},
  deleteChatNovelEpicCommentState: false,
  updateChatNovelEpic: false,
  chatNovelDetailEpicData: {
    index: -1,
    docType: 'comments'
  },
  epicDownloadFile: [],
  epicDownloadFileState: false,
}


export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_CHAT_NOVEL :
      return {
        ...state,
        chatNovels: actions.payload,
        // bookLoading : false
      }
    case GET_CHAT_NOVEL_EPIC :
      return {
        ...state,
        chatNovelEpics: actions.payload,
        // bookLoading : false
      }
    case CHAT_NOVEL_DETAIL :
      return {
        ...state,
        chatNovelDetail: actions.payload,
      }
    case DELETE_CHAT_NOVEL_EPIC_COMMENT :
      return {
        ...state,
        deleteChatNovelEpicCommentState : true,
        chatNovelDetail : actions.payload,
      }
    case UPDATE_CHAT_NOVEL_EPIC :
      return {
        ...state,
        updateChatNovelEpic : true,
        chatNovelDetail : actions.payload,
      }
    case GET_EPIC_DOWNLOAD_FILE :
      return {
        ...state,
        epicDownloadFile: actions.payload,
      }
    default :
      return state;
  }
}