import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "antd/dist/antd.min.css";
import "semantic-ui-css/semantic.min.css";
import "./components/stylesheets/main.scss";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// ReactDOM.render(
//   <Provider store={store}>
//     <>
//       <App />
//     </>
//   </Provider>,
//   document.getElementById("root"),
// );

serviceWorkerRegistration.register();
