import firebase from "firebase/compat/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/compat/firestore";

async function initFirebase() {
  var firebaseConfig = {
    apiKey: localStorage.getItem("REACT_APP_APIKEY"),
    authDomain: localStorage.getItem("REACT_APP_AUTHDOMAIN"),
    projectId: localStorage.getItem("REACT_APP_PROJECTID"),
    storageBucket: localStorage.getItem("REACT_APP_STORAGEBUCKET"),
    messagingSenderId: localStorage.getItem("REACT_APP_MESSAGINGSENDERID"),
    appId: localStorage.getItem("REACT_APP_APPID"),
    measurementId: localStorage.getItem("REACT_APP_MEASUREMENTID"),
    databaseURL: "https://dankkumi-default-rtdb.asia-southeast1.firebasedatabase.app",
  };
  if (!firebase.apps.length) {
    await firebase.initializeApp(firebaseConfig);
  }
  if (!firebase.auth().currentUser) {
    const customToken = localStorage.getItem("REACT_APP_CUSTOM_TOKEN_FIREBASE");
    if (customToken) {
      try {
        var tx = await firebase.auth().signInWithCustomToken(customToken);
      } catch (error) {
        console.error("Firebase auth error:", error);
      }
    }
  }
}

export { initFirebase };
