import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Boot from "./redux/boot";
import Routes from "./router";
import {
  getPushNotification1,
  setPushNotification1,
  setSocket,
  setSocketId,
  setPushNotification1Status,
} from "./redux/actions/pushNotificationActions";
import io from "socket.io-client";
import { sendNotification } from "./functions/index";
import "antd/dist/antd.min.css";

const App = (props) => {
  useEffect(() => {
    const newSocket = io(`/node_stream/socket`);
    props.setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (props.socket) {
      if (props.admin) {
        props.socket.emit("addUser", props.admin._id);
      }

      props.socket.on("connect", () => {
        props.setSocketId(props.socket.id);
      });

      if (localStorage.getItem("socketId")) {
        props.socket.on("push_notification_1", ({ data, users, event }) => {
          props.getPushNotification1();

          if (props.socket.id == localStorage.getItem("socketId")) {
            if ("serviceWorker" in navigator) {
              sendNotification(props.token, {
                operationType: "insert",
                collType: data.type,
                id: data._id,
              })
                .then(() => {})
                .catch((err) => console.error(err));
            }
          } else {
            console.log("no");
          }
        });
        return () => props.socket.off("push_notification_1");
      }
    }
  }, [props.socket, props.admin]);

  return (
    <>
      <Routes />
    </>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

const mapStateToProps = (state) => {
  return {
    admin: state.auth.admin,
    token: state.auth.token,
    socket: state.pushNotification.socket,
    socketId: state.pushNotification.socketId,
    pushNotification1: state.pushNotification.pushNotification1,

    pushNotification1Status: state.pushNotification.pushNotification1Status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPushNotification1: (data) => dispatch(setPushNotification1(data)),
    getPushNotification1: () => dispatch(getPushNotification1()),

    setSocket: (data) => dispatch(setSocket(data)),
    setSocketId: (data) => dispatch(setSocketId(data)),

    setPushNotification1Status: (data) => dispatch(setPushNotification1Status(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
