import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, BrowserRouter } from "react-router-dom";
import Loading from "./components/share/loading";
import { refreshLogin } from "./redux/actions/authActions";
import LayoutBuilder from "./components/layout/layoutBulder";
import LayoutBuilderBeta from "./components/layout/layoutBuilderBeta";
import { initFirebase } from "./firebase/init";

const betaRoutes = [
  {
    path: "/ondalbot",
    component: lazy(() => import("./components/ondalbot/OndalBotMain")),
  },
];

const generalRoutes = [
  {
    path: "/login",
    component: lazy(() => import("./components/auth/Login")),
  },
  {
    path: "/privacy",
    component: lazy(() => import("./components/share/privacyAgree")),
  },
  {
    path: "/v2/privacy",
    component: lazy(() => import("./components/share/v2/privacy")),
  },
  {
    path: "/v2/usage",
    component: lazy(() => import("./components/share/v2/usage")),
  },
  {
    path: "/v2/usagedetail",
    component: lazy(() => import("./components/share/v2/usageDetail")),
  },
  {
    path: "/communityguide",
    component: lazy(() => import("./components/share/communityGuide")),
  },
  {
    path: "/address",
    component: lazy(() => import("./components/address/Address")),
  },
];

const adminRoutes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("./components/Home")),
  },
  //   App users
  {
    path: "/blockhistory",
    exact: true,
    component: lazy(() => import("./components/blockHistory/BlockHistory")),
  },
  {
    path: "/appusers",
    exact: true,
    component: lazy(() => import("./components/app_user/AppUsers")),
  },
  {
    path: "/appuserleave",
    exact: true,
    component: lazy(() => import("./components/app_user_leave/AppUserLeaveList")),
  },
  // AVATAR
  {
    path: "/generalavatars",
    component: lazy(() => import("./components/avatar/GeneralAvatarLists")),
  },
  {
    path: "/specialavatars",
    component: lazy(() => import("./components/avatar/SpecialAvatarLists")),
  },
  // BLOG POSTING RENEWAL
  {
    path: "/blogpostings",
    exact: true,
    component: lazy(() => import("./components/blog_posting/BlogLists")),
  },
  {
    path: "/blogpostings/:blogpostingid",
    component: lazy(() => import("./components/blog_posting/BlogLists")),
  },
  {
    path: "/blogfanpostings",
    exact: true,
    component: lazy(() => import("./components/blog_fan_posting/BlogLists")),
  },
  {
    path: "/blogfanpostings/:blogfanpostingid",
    component: lazy(() => import("./components/blog_fan_posting/BlogLists")),
  },

  // NOTICE POSTING
  {
    path: "/noticepostings",
    exact: true,
    component: lazy(() => import("./components/notice_posting/NoticePostings")),
  },

  // MAP POSTING
  {
    path: "/maplists",
    exact: true,
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  {
    path: "/maplists/:mappostingid",
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  // VIDEO
  {
    path: "/videolist",
    component: lazy(() => import("./components/video/VideoLists")),
  },
  {
    path: "/videocategory",
    component: lazy(() => import("./components/video/VideoCategory")),
  },
  {
    path: "/videosort",
    component: lazy(() => import("./components/video/VideoSort")),
  },
  // QUIZ
  {
    path: "/quizzesadmin",
    component: lazy(() => import("./components/quiz_admin/QuizAdminMain")),
  },
  {
    path: "/quizzesadminteam",
    component: lazy(() => import("./components/quiz_team/QuizAdminTeam")),
  },
  {
    path: "/quizevent",
    component: lazy(() => import("./components/quiz_event/NoticePostings")),
  },
  {
    path: "/quizform",
    component: lazy(() => import("./components/quiz/QuizForm")),
  },
  {
    path: "/quizzes",
    exact: true,
    component: lazy(() => import("./components/quiz/Quizzes")),
  },
  {
    path: "/quizzes/:id",
    component: lazy(() => import("./components/quiz/EditQuiz")),
  },

  {
    path: "/appusers/:appuserid/blogfanpostings",
    component: lazy(() => import("./components/blog_fan_posting/BlogLists")),
  },

  {
    path: "/appusers/:appuserid/mappostings",
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  {
    path: "/testappusers",
    exact: true,
    component: lazy(() => import("./components/app_user/TestAppUsers")),
  },

  {
    path: "/qandas",
    component: lazy(() => import("./components/qna/QAndAsNew")),
  },

  {
    path: "/pointsettings",
    exact: true,
    component: lazy(() => import("./components/setting/PointSettings")),
  },
  {
    path: "/generalsettings",
    exact: true,
    component: lazy(() => import("./components/setting/GeneralSettings")),
  },
  {
    path: "/reports",
    exact: true,
    component: lazy(() => import("./components/reportRenewal/Reports")),
  },
  {
    path: "/reports/blogfanpostings/:blogfanpostingid",
    exact: true,
    component: lazy(() => import("./components/reportRenewal/ReportedBlogFanPosting")),
  },
  {
    path: "/reports/blogfanpostings/:blogfanpostingid/blogfanpostingcomments/:blogfanpostingcommentid",
    exact: true,
    component: lazy(() => import("./components/reportRenewal/ReportedBlogFanPosting")),
  },
  {
    path: "/reports/blogpostings/:blogpostingid/blogpostingcomments/:blogpostingcommentid",
    exact: true,
    component: lazy(() => import("./components/blog_posting/BlogLists")),
  },
  {
    path: "/reports/mappostings/:mappostingid",
    exact: true,
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  {
    path: "/reports/mappostings/:mappostingid/mappostingcomments/:mappostingcommentid",
    exact: true,
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  {
    path: "/reports/videopostings/:videopostingid/videopostingcomments/:videopostingcommentid",
    exact: true,
    component: lazy(() => import("./components/video/VideoLists")),
  },
  {
    path: "/reports/quizzes/:quizid/quizcomments/:quizcommentid",
    exact: true,
    component: lazy(() => import("./components/quiz/Quizzes")),
  },
  {
    path: "/devonly",
    exact: true,
    component: lazy(() => import("./components/dev/DevOnly")),
  },
  {
    path: "/unreadpage/report",
    exact: true,
    component: lazy(() => import("./components/reportRenewal/Reports")),
  },
  {
    path: "/unreadpage/q_and_a",
    exact: true,
    component: lazy(() => import("./components/qna/QAndAsNew")),
  },

  {
    path: "/unreadpage/commerce_purchase",
    exact: true,
    component: lazy(() => import("./components/commerce/purchase/CommercePurchases")),
  },
  {
    path: "/downloads",
    exact: true,
    component: lazy(() => import("./components/download/Downloads")),
  },

  // DASHBOARD
  {
    path: "/contentdashboard",
    exact: true,
    component: lazy(() => import("./components/dashboard/ContentDashboard")),
  },
  {
    path: "/dashboard/content/popularvideostable/:chosendate",
    exact: true,
    component: lazy(() => import("./components/data_visualization/content/PopularVideosTable")),
  },
  {
    path: "/dashboard/content/popularpostingsstables/:chosendate",
    exact: true,
    component: lazy(() => import("./components/data_visualization/content/popular_posting/PopularPostingsTables")),
  },
  {
    path: "/dashboard/content/popularvideos/:popularvideoid",
    exact: true,
    component: lazy(() => import("./components/video/VideoLists")),
  },
  {
    path: "/dashboard/video_posting_comment/:postid",
    exact: true,
    component: lazy(() => import("./components/video/VideoLists")),
  },
  {
    path: "/dashboard/blog_fan_posting/:alltargetid",
    exact: true,
    component: lazy(() => import("./components/data_visualization/content/AllPostingsBlogFanPosting")),
  },
  {
    path: "/dashboard/blog_posting/:postid",
    exact: true,
    component: lazy(() => import("./components/blog_posting/BlogLists")),
  },
  {
    path: "/dashboard/map_posting_comment/:postid",
    exact: true,
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  {
    path: "/dashboard/quiz_comment/:postid",
    exact: true,
    component: lazy(() => import("./components/quiz/Quizzes")),
  },
  {
    path: "/dashboard/chat_novel_epic_comment/:novelid/:epicid",
    exact: true,
    component: lazy(() => import("./components/chat_novel/ChatNovel")),
  },
  {
    path: "/dashboard/content/allpostings/blog_fan_posting/:alltargetid",
    exact: true,
    component: lazy(() => import("./components/data_visualization/content/AllPostingsBlogFanPosting")),
  },
  {
    path: "/dashboard/content/popularpostings/blog_fan_posting/:alltargetid",
    exact: true,
    component: lazy(() => import("./components/data_visualization/content/AllPostingsBlogFanPosting")),
  },
  {
    path: "/dashboard/content/popularpostings/map_posting/:popularmappostid",
    exact: true,
    component: lazy(() => import("./components/map_posting/MapLists")),
  },
  {
    path: "/commercesort",
    component: lazy(() => import("./components/commerce/sort/CommerceSort")),
  },
  {
    path: "/commercebook",
    component: lazy(() => import("./components/commerce/book/CommerceBook")),
  },
  {
    path: "/commercegoods",
    component: lazy(() => import("./components/commerce/goods/CommerceGoods")),
  },
  {
    path: "/ebooks",
    exact: true,
    component: lazy(() => import("./components/commerce/ebook/EBooks")),
  },
  {
    path: "/ebookcontent",
    exact: true,
    component: lazy(() => import("./components/commerce/ebook/EBookContent")),
  },
  {
    path: "/ebooksettings",
    exact: true,
    component: lazy(() => import("./components/commerce/ebook/EBookSettings")),
  },
  {
    path: "/webtoons",
    exact: true,
    component: lazy(() => import("./components/commerce/web_toon/WebToons")),
  },
  {
    path: "/webtoonsettings",
    exact: true,
    component: lazy(() => import("./components/commerce/web_toon/WebToonSettings")),
  },
  {
    path: "/commercepurchase",
    exact: true,
    component: lazy(() => import("./components/commerce/purchase/CommercePurchases")),
  },
  {
    path: "/chatnovel",
    exact: true,
    component: lazy(() => import("./components/chat_novel/ChatNovel")),
  },
  {
    path: "/chatnovel/epic",
    exact: true,
    component: lazy(() => import("./components/chat_novel/epic/ChatNovelEpic")),
  },

  {
    path: "/chatnovelrenewal/novellist",
    exact: true,
    component: lazy(() => import("./components/chat_novel_renewal/novel/NovelList")),
  },

  {
    path: "/chatnovelrenewal/epiclist",
    exact: true,
    component: lazy(() => import("./components/chat_novel_renewal/epic/EpicList")),
  },
  {
    path: "/ondalbot",
    component: lazy(() => import("./components/ondalbot/OndalBotMain")),
  },
];
var checkAdmin = false;
function PrivateRoute({ children, ...rest }) {
  const adminState = useSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState(true);
  const dispatch = useDispatch();

  useMemo(() => {
    if (adminState.isAuthenticated) {
      setIsAdmin(true);
    } else if (adminState.token) {
      setIsAdmin(true);
      if (checkAdmin == false) {
        dispatch(refreshLogin(adminState.token));
      }
      checkAdmin = true;
    } else {
      setIsAdmin(false);
    }
  }, [adminState]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const adminState = useSelector((state) => state.auth);
  const isBetaUser = adminState.isAuthenticated && adminState.admin.role === "beta";
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(() => {
    if (adminState.isAuthenticated) {
      initFirebase().then(() => {
        setFirebaseInitialized(true);
      });
    } else {
      setFirebaseInitialized(false);
    }
  }, [adminState.isAuthenticated]);

  if (adminState.isAuthenticated && !firebaseInitialized) {
    return <Suspense fallback={<Loading />}></Suspense>;
  } else {
    return (
      <>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            {isBetaUser && firebaseInitialized ? (
              <LayoutBuilderBeta
                router={betaRoutes.map((route, index) => (
                  <Route key={index}>
                    <route.component />
                  </Route>
                ))}
              />
            ) : (
              <>
                {generalRoutes.map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact}>
                    <route.component />
                  </Route>
                ))}
                <LayoutBuilder
                  router={adminRoutes.map((route, index) => (
                    <PrivateRoute key={index} path={route.path} exact={route.exact}>
                      <route.component />
                    </PrivateRoute>
                  ))}
                />
              </>
            )}
          </BrowserRouter>
        </Suspense>
      </>
    );
  }
}
