import {
  SETTINGS_LOADING,
  GET_SETTINGS,
  EDIT_SETTINGS,
  EDIT_SETTINGS_STATUS,
  CHANGE_QUIZ_SCHEDULER_STATUS,
  CHANGE_QUIZ_SCHEDULER_STATUS_STATE,
} from "../actions/types";

const initialState = {
  settingsLoading: false,
  settings: {},
  editSettingsStatus: false,
  changeQuizSchedulerStatusState: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTINGS_LOADING:
      return {
        ...state,
        settingsLoading: true,
      };
    case GET_SETTINGS:
      return {
        ...state,
        settingsLoading: false,
        settings: action.payload,
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        editSettingsStatus: true,
        settings: action.payload,
      };
    case EDIT_SETTINGS_STATUS:
      return {
        ...state,
        editSettingsStatus: false,
      };
    case CHANGE_QUIZ_SCHEDULER_STATUS:
      return {
        ...state,
        changeQuizSchedulerStatusState: true,
        settings: action.payload,
      };
    case CHANGE_QUIZ_SCHEDULER_STATUS_STATE:
      return {
        ...state,
        changeQuizSchedulerStatusState: false,
      };

    default:
      return state;
  }
}
