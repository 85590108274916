import {
  GET_SPECIAL_AVATARS,
  ADD_SPECIAL_AVATARS,
  EDIT_SPECIAL_AVATARS,
  GET_LEVEL_SETTINGS,
  LEVEL_SETTINGS_LOADING,
  EDIT_LEVEL_SETTING,
  ADD_AVATAR_LEVEL_SETTING,
  EDIT_AVATAR_LEVEL_SETTING,
  ADD_SPECIAL_AVATARS_STATUS,
  EDIT_SPECIAL_AVATARS_STATUS,
} from "../actions/types";

const initialState = {
  levelSettingsLoading: false,
  levelSettingProcessing: false,
  levelSettings: [],
  specialAvatars: [],
  addSpecialAvatarsStatus: false,
  editSpecialAvatarsStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LEVEL_SETTINGS_LOADING:
      return {
        ...state,
        levelSettingsLoading: false,
      };
    case GET_SPECIAL_AVATARS:
      return {
        ...state,
        levelSettingsLoading: false,
        specialAvatars: action.payload,
      };
    case ADD_SPECIAL_AVATARS:
      return {
        ...state,
        addSpecialAvatarsStatus: true,
        levelSettingsLoading: true,
        specialAvatars: [...state.specialAvatars, action.payload],
      };
    case ADD_SPECIAL_AVATARS_STATUS:
      return {
        ...state,
        addSpecialAvatarsStatus: false,
      };
    case EDIT_SPECIAL_AVATARS:
      return {
        ...state,
        editSpecialAvatarsStatus: true,
        levelSettingsLoading: true,
        specialAvatars: state.specialAvatars.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case EDIT_SPECIAL_AVATARS_STATUS:
      return {
        ...state,
        editSpecialAvatarsStatus: false,
      };

    case GET_LEVEL_SETTINGS:
      return {
        ...state,
        levelSettingsLoading: false,
        levelSettings: action.payload,
      };
    case EDIT_LEVEL_SETTING:
      return {
        ...state,
        levelSettingsLoading: true,
        levelSettings: state.levelSettings.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case ADD_AVATAR_LEVEL_SETTING:
      return {
        ...state,
        levelSettingsLoading: true,
        levelSettings: state.levelSettings.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };

    case EDIT_AVATAR_LEVEL_SETTING:
      return {
        ...state,
        levelSettingsLoading: true,
        levelSettings: state.levelSettings.map((item) =>
          item.level === action.payload.level
            ? {
                ...item,
                avatars: item.avatars.map((avatar) =>
                  avatar._id == action.payload._id ? action.payload : avatar
                ),
              }
            : item
        ),
      };
    default:
      return state;
  }
}
