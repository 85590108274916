import {
    CREATE_COMMERCE_GOODS,
    GET_COMMERCE_GOODS,
    EDIT_COMMERCE_GOODS,
    COMMERCE_ERROR,
    GOODS_LOADING,
    DELETE_GOODS,
    DELETE_GOODSES,
    DELETE_GOODS_STATUS,
    ADD_GOODS_STATUS,
    EDIT_GOODS_STATUS,
    CREATE_GOODS_CATEGORY,
    CATEGORY_STATUS,
    EDIT_GOODS_CATEGORY,
    GET_GOODS_CATEGORY,
    GOODS_DETAIL,
    ADD_GOODS_COMMENT,
    DELETE_GOODS_COMMENT,
    GET_COMMERCE_GOODS_RELEVANT_DATA
} from '../actions/types'

const initialState = {
    commerceGoods : [],
    commerceError : "",
    addGoodsState : false,
    deleteGoodsState : false,
    goodsLoading : false,
    categoryStatus : false,
    goodsDetail : {},
    goodsRelevantData : {},
}


export default function(state = initialState , actions) {
    switch(actions.type) {
        case GOODS_LOADING : 
            return {
                ...state,
                goodsLoading : true
            }
        case CREATE_COMMERCE_GOODS :
            return {
                ...state,
                commerceGoods : actions.payload,
                addGoodsState : true,
                goodsLoading : false
            }
        case GET_COMMERCE_GOODS : 
            return {
                ...state,
                commerceGoods : actions.payload,
                goodsLoading : false
            }
        case COMMERCE_ERROR : 
            return {
                ...state,
                commerceError : actions.payload
            }
        case EDIT_COMMERCE_GOODS : 
            return {
                ...state,
                commerceGoods : actions.payload,
                editGoodsStatus : true,
                goodsLoading : false
            }
        case DELETE_GOODS :
            return {
                ...state ,
                deleteGoodsState : true,
                commerceGoods : actions.payload
            }
        case DELETE_GOODSES :
            return {
                ...state ,
                deleteGoodsState : true,
                commerceGoods : actions.payload
            }
        case DELETE_GOODS_STATUS : 
            return {
                ...state,
                deleteGoodsState : false
            }
        case ADD_GOODS_STATUS :
            return {
                ...state,
                addGoodsState : false
            }
        case EDIT_GOODS_STATUS :
            return {
                ...state,
                editGoodsStatus : false
            }
        case CREATE_GOODS_CATEGORY : 
            return  {
                ...state,
                categoryStatus : true,
                categoryList : actions.payload
            }
        case CATEGORY_STATUS : 
            return {
                ...state,
                categoryStatus : false
            }
        case EDIT_GOODS_CATEGORY : 
            return {
                ...state,
                categoryStatus : true,
                categoryList : actions.payload
            }
        case GET_GOODS_CATEGORY : 
            return {
                ...state,
                categoryList : actions.payload
            }
        case GOODS_DETAIL :
            return {
                ...state,
                goodsDetail : actions.payload
            }
        case ADD_GOODS_COMMENT : 
            return {
                ...state ,
                goodsDetail : actions.payload
            }
        case DELETE_GOODS_COMMENT : 
            return {
                ...state,
                goodsDetail : actions.payload
            }
        case GET_COMMERCE_GOODS_RELEVANT_DATA :
            return {
                ...state,
                goodsRelevantData : actions.payload
            }
        default :
            return state;
    }
}