import React from "react";
import { Layout, Menu } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppHeader from "./appHeader";
import "../stylesheets/layoutBuilder.scss";
import { RobotFilled } from "@ant-design/icons";
const { Content, Sider, Header } = Layout;

const LayoutBuilder = ({ router }) => {
  const adminState = useSelector((state) => state.auth);
  const sider = () => {
    return (
      <Sider
        width={200}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]} mode="inline">
          <Menu.Item key="/" className="itemMenu">
            <Link to="/">HOME</Link>
          </Menu.Item>

          <p className="itemTitle">온달봇</p>
          <Menu.Item key="/ondalbot" className="itemMenu">
            <Link to="/ondalbot">
              <RobotFilled className="ondalbotTitle" />
              온달봇 베타
            </Link>
          </Menu.Item>

          <p className="itemTitle">Dashboard</p>
          <Menu.Item key="/contentdashboard" className="itemMenu">
            <Link to="/contentdashboard">컨텐츠</Link>
          </Menu.Item>

          <p className="itemTitle">사용자</p>
          <Menu.Item key="/appusers" className="itemMenu">
            <Link to="/appusers">사용자 정보</Link>
          </Menu.Item>
          <Menu.Item key="/blockhistory" className="itemMenu">
            <Link to="/blockhistory">차단 기록</Link>
          </Menu.Item>
          <Menu.Item key="/appuserleave" className="itemMenu">
            <Link to="/appuserleave">탈퇴 기록</Link>
          </Menu.Item>
          <p className="itemTitle">공지사항</p>
          <Menu.Item key="/noticepostings" className="itemMenu">
            <Link to="/noticepostings">공지사항 포스팅</Link>
          </Menu.Item>

          <p className="itemTitle">아바타</p>
          <Menu.Item key="/generalavatars" className="itemMenu">
            <Link to="/generalavatars">기본 아바타</Link>
          </Menu.Item>
          <Menu.Item key="/specialavatars" className="itemMenu">
            <Link to="/specialavatars">스페셜 아바타</Link>
          </Menu.Item>

          <p className="itemTitle">블로그 포스팅</p>
          <Menu.Item key="/blogpostings" className="itemMenu">
            <Link to="/blogpostings">선생님 포스팅</Link>
          </Menu.Item>
          <Menu.Item key="/blogfanpostings" className="itemMenu">
            <Link to="/blogfanpostings">사용자 포스팅</Link>
          </Menu.Item>
          <p className="itemTitle">영상 컨텐츠</p>
          <Menu.Item key="/videocategory" className="itemMenu">
            <Link to="/videocategory">영상 카테고리</Link>
          </Menu.Item>
          <Menu.Item key="/videolist" className="itemMenu">
            <Link to="/videolist">영상 컨텐츠 리스트</Link>
          </Menu.Item>
          <Menu.Item key="/videosort" className="itemMenu">
            <Link to="/videosort">영상 컨텐츠 정렬</Link>
          </Menu.Item>

          <p className="itemTitle">대동여지도</p>
          <Menu.Item key="/maplists" className="itemMenu">
            <Link to="/maplists">대동여지도 포스팅</Link>
          </Menu.Item>

          <p className="itemTitle">퀴즈</p>
          <Menu.Item key="/quizevent" className="itemMenu">
            <Link to="/quizevent">퀴즈 이벤트</Link>
          </Menu.Item>
          <Menu.Item key="/quizzes" className="itemMenu">
            <Link to="/quizzes">퀴즈 리스트</Link>
          </Menu.Item>
          <Menu.Item key="/quizzesadmin" className="itemMenu">
            <Link to="/quizzesadmin">퀴즈 설정</Link>
          </Menu.Item>
          <Menu.Item key="/quizzesadminteam" className="itemMenu">
            <Link to="/quizzesadminteam">퀴즈 팀 관리</Link>
          </Menu.Item>

          <p className="itemTitle">Q & A</p>
          <Menu.Item key="/qandas" className="itemMenu">
            <Link to="/qandas">Q & A 리스트</Link>
          </Menu.Item>

          <p className="itemTitle">세팅</p>
          <Menu.Item key="/generalsettings" className="itemMenu">
            <Link to="/generalsettings">일반</Link>
          </Menu.Item>
          <Menu.Item key="/pointsettings" className="itemMenu">
            <Link to="/pointsettings">포인트</Link>
          </Menu.Item>

          <p className="itemTitle">신고</p>
          <Menu.Item key="/reports" className="itemMenu">
            <Link to="/reports">신고된게시글</Link>
          </Menu.Item>

          <p className="itemTitle">다운로드</p>
          <Menu.Item key="/downloads" className="itemMenu">
            <Link to="/downloads">다운로드리스트</Link>
          </Menu.Item>
          <p className="itemTitle">커머스</p>
          <Menu.Item key="/commercepurchase" className="itemMenu">
            <Link to="/commercepurchase">커머스 구매 목록</Link>
          </Menu.Item>
          <Menu.Item key="/commercesort" className="itemMenu">
            <Link to="/commercesort">커머스 정렬</Link>
          </Menu.Item>
          <Menu.Item key="/commercebook" className="itemMenu">
            <Link to="/commercebook">도서</Link>
          </Menu.Item>
          <Menu.Item key="/commercegoods" className="itemMenu">
            <Link to="/commercegoods">굿즈</Link>
          </Menu.Item>
          <Menu.Item key="/ebooks" className="itemMenu">
            <Link to="/ebooks">eBook</Link>
          </Menu.Item>
          <Menu.Item key="/webtoons" className="itemMenu">
            <Link to="/webtoons">WebToon</Link>
          </Menu.Item>

          <p className="itemTitle">챗소설v2</p>

          <Menu.Item key="/chatnovelrenewal/novellist" className="itemMenu">
            <Link to="/chatnovelrenewal/novellist">챗소설</Link>
          </Menu.Item>
          <Menu.Item key="/chatnovelrenewal/epiclist" className="itemMenu">
            <Link to="/chatnovelrenewal/epiclist">회차</Link>
          </Menu.Item>

          <p className="itemTitle">챗소설</p>
          <Menu.Item key="/chatnovel" className="itemMenu">
            <Link to="/chatnovel">챗소설 목록</Link>
          </Menu.Item>
          <Menu.Item key="/chatnovel/epic" className="itemMenu">
            <Link to="/chatnovel/epic">회차 목록</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  };
  return adminState.isAuthenticated ? (
    <div className="appLayout">
      <Layout>
        {sider()}
        <Layout
          className="nestedLayout"
          width={200}
          style={{
            overflow: "auto",
            height: "100vh",
            width: "calc(100% - 200px)",
            position: "fixed",
            left: 200,
          }}
        >
          <AppHeader />
          <Content className="appContent">{router}</Content>
        </Layout>
      </Layout>
    </div>
  ) : (
    <>{router}</>
  );
};

export default LayoutBuilder;
