import { combineReducers } from "redux";
import authReducer from "./authReducer";
import appUserReducer from "./appUserReducer";
import mapPostingReducer from "./mapPostingReducer";
import blogPostingReducer from "./blogPostingReducer";
import blogFanPostingReducer from "./blogFanPostingReducer";
import levelSettingReducer from "./levelSettingReducer";
import quizReducer from "./quizReducer";
import videoReducer from "./videoReducer";
import noticePostingReducer from "./noticePostingReducer";
import productReducer from "./productReducer";
import qAndAReducer from "./qAndAReducer";
import settingReducer from "./settingReducer";
import pointHistoryReducer from "./pointHistoryReducer";
// import reportReducer from "./reportReducer";

import reportReducer from "./reportReducerRenwal";

import pushNotificationReducer from "./pushNotificationReducer";
import downloadReducer from "./downloadReducer";
import dashboardReducer from "./dashboardReducer";
import commerceBookReducer from "./commerceBookReducer";
import webToonReducer from "./webToonReducer";
import eBookReducer from "./eBookReducer";
import tagsReducer from "./tagsReducer";
import commerceGoodsReducer from "./commerceGoodsReducer";
import commercePurchaseReducer from "./commercePurchaseReducer";
import chatNovelReducer from "./chatNovelReducer";
import blockHistoryReducer from "./blockHistoryReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  appUser: appUserReducer,
  mapPosting: mapPostingReducer,
  blogPosting: blogPostingReducer,
  blogFanPosting: blogFanPostingReducer,
  levelSetting: levelSettingReducer,
  quiz: quizReducer,
  videoPosting: videoReducer,
  noticePosting: noticePostingReducer,
  product: productReducer,
  qAndA: qAndAReducer,
  setting: settingReducer,
  pointHistory: pointHistoryReducer,
  report: reportReducer,
  pushNotification: pushNotificationReducer,
  download: downloadReducer,
  dashboard: dashboardReducer,
  book: commerceBookReducer,
  webToon: webToonReducer,
  eBook: eBookReducer,
  tags: tagsReducer,
  goods: commerceGoodsReducer,
  commercePurchase: commercePurchaseReducer,
  chatNovel: chatNovelReducer,
  blockHistory: blockHistoryReducer,
});

export default rootReducer;
