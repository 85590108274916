import {
  QUIZZES_LOADING,
  GET_QUIZZES,
  QUIZ_LOADING,
  GET_QUIZ,
  ADD_QUIZ,
  ADD_QUIZ_STATUS,
  ADD_QUIZ_SET,
  ADD_QUIZ_SET_STATUS,
  EDIT_QUIZ,
  EDIT_QUIZ_STATUS,
  SET_GET_QUIZZES_STATUS,
  GET_DAILY_QUIZ_PARTICIPANTS_WITH_DATE,
  SET_DETAIL_QUIZZ_ID,
  GET_DETAIL_QUIZ_COMMENT,
  GET_TEMP_QUIZ,
  DELETE_QUIZ_COMMENT,
} from "../actions/types";

const initialState = {
  quizzesLoading: false,
  quizzes: [],
  quizLoading: false,
  quiz: {},
  addQuizStatus: false,
  addQuizSetStatus: false,
  editQuizStatus: false,
  getQuizzesStatus: false,
  dailyQuizParticipantsWithDate: { data: [], dates: [] },
  quizDetailViewId: null,
  quizComment: [],
  tempQuiz: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QUIZZES_LOADING:
      return {
        ...state,
        quizzesLoading: true,
      };
    case GET_QUIZZES:
      return {
        ...state,
        quizzesLoading: false,
        getQuizzesStatus: false,
        quizzes: action.payload,
      };
    case SET_GET_QUIZZES_STATUS:
      return {
        ...state,
        getQuizzesStatus: action.payload,
      };

    case QUIZ_LOADING:
      return {
        ...state,
        quizLoading: true,
      };
    case GET_QUIZ:
      return {
        ...state,
        quizLoading: false,
        quiz: action.payload,
      };
    case ADD_QUIZ:
      return {
        ...state,
        addQuizStatus: true,
        quizzes: [action.payload, ...state.quizzes],
      };
    case ADD_QUIZ_STATUS:
      return {
        ...state,
        addQuizStatus: false,
      };
    case ADD_QUIZ_SET:
      return {
        ...state,
        addQuizSetStatus: true,
        quizzes: [...action.payload, ...state.quizzes],
      };
    case ADD_QUIZ_SET_STATUS:
      return {
        ...state,
        addQuizSetStatus: false,
      };
    case EDIT_QUIZ:
      return {
        ...state,
        editQuizStatus: true,
        quizzes: state.quizzes.map((quiz) => {
          if (quiz["_id"] === action.payload["_id"]) {
            return action.payload;
          } else {
            return quiz;
          }
        }),
      };
    case EDIT_QUIZ_STATUS:
      return {
        ...state,
        editQuizStatus: false,
      };
    case GET_DAILY_QUIZ_PARTICIPANTS_WITH_DATE:
      return {
        ...state,
        dailyQuizParticipantsWithDate: action.payload,
      };
    case SET_DETAIL_QUIZZ_ID:
      return {
        ...state,
        quizDetailViewId: action.payload,
      };
    case GET_DETAIL_QUIZ_COMMENT:
      return {
        ...state,
        quizComment: action.payload,
      };
    case GET_TEMP_QUIZ:
      return {
        ...state,
        tempQuiz: action.payload,
      };
    case DELETE_QUIZ_COMMENT:
      return {
        ...state,
        quizComment: action.payload,
      };

    default:
      return state;
  }
}
