import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_FAIL_FIREBASE,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_FAIL_FIREBASE,
} from "../actions/types";
// import { initFirebase } from "../../firebase/init";

const initialState = {
  admin: null,
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: false,
  errMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        admin: action.payload,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      for (let key in action.payload) {
        if (key.includes("REACT_APP")) {
          localStorage.setItem(key, action.payload[key]);
        }
      }
      //   initFirebase();

      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
        admin: action.payload.admin,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGIN_FAIL_FIREBASE:
    case LOGOUT_SUCCESS:
    case LOGOUT_FAIL:
    case REGISTER_FAIL:
    case REGISTER_FAIL_FIREBASE:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        admin: null,
        isAuthenticated: false,
        isLoading: false,
        errMessage: action.payload,
      };
    default:
      return state;
  }
}
