import {
  ADD_EBOOK_CATEGORY,
  SET_ADD_EBOOK_CATEGORY_STATUS,
  ADD_EBOOK_CATEGORY_ERROR,
  SET_ADD_EBOOK_CATEGORY_ERROR_STATUS,
  GET_EBOOK_CATEGORIES,
  ADD_EBOOK,
  SET_ADD_EBOOK_STATUS,
  ADD_EBOOK_ERROR,
  SET_ADD_EBOOK_ERROR_STATUS,
  GET_EBOOKS,
  EDIT_EBOOK,
  SET_EDIT_EBOOK_STATUS,
  GET_EBOOK_RELEVANT_DATA,
  CHANGE_EBOOKS_PERMISSION,
  SET_EBOOKS_PERMISSION_STATUS,
  DELETE_EBOOK,
  SET_DELETE_EBOOK_STATUS,
  GET_EBOOK,
  ADD_EBOOK_COMMENT,
  DELETE_EBOOK_COMMENT,
  EDIT_EBOOK_COMMENT,
  SET_EDIT_EBOOK_COMMENT_STATUS,
  SET_EBOOK_CONTENT,
  EDIT_EBOOK_CATEGORY,
  SET_EDIT_EBOOK_CATEGORY_STATUS,
  DELETE_EBOOK_CATEGORY,
  SET_DELETE_EBOOK_CATEGORY_STATUS,
} from "../actions/types";

const initialState = {
  eBook: {},
  eBooks: [],
  addEBookStatus: false,
  editEBookStatus: false,
  addEBookError: null,
  addEBookCategoryStatus: false,
  addEBookCategoryError: null,
  eBookCategories: [],
  eBookRelevantData: [],
  changeEBooksPermissionStatus: false,
  deleteEBookStatus: false,
  editEBookCommentStatus: false,
  editEBookCategoryStatus: false,
  deleteEBookCategoryStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EBOOK:
      return {
        ...state,
        eBook: action.payload,
      };
    case GET_EBOOKS:
      return {
        ...state,
        eBooks: action.payload,
      };
    case ADD_EBOOK_CATEGORY:
      return {
        ...state,
        addEBookCategoryStatus: true,
        eBookCategories: [...state.eBookCategories, action.payload],
      };
    case SET_ADD_EBOOK_CATEGORY_STATUS:
      return {
        ...state,
        addEBookCategoryStatus: false,
      };
    case ADD_EBOOK_CATEGORY_ERROR:
      return {
        ...state,
        addEBookCategoryError: action.payload,
      };
    case SET_ADD_EBOOK_CATEGORY_ERROR_STATUS:
      return {
        ...state,
        addEBookCategoryError: null,
      };
    case EDIT_EBOOK_CATEGORY:
      return {
        ...state,
        editEBookCategoryStatus: true,
        eBookCategories: state.eBookCategories.map((item) =>
          item["_id"] === action.payload["_id"] ? action.payload : item
        ),
      };
    case SET_EDIT_EBOOK_CATEGORY_STATUS:
      return {
        ...state,
        editEBookCategoryStatus: false,
      };

    case DELETE_EBOOK_CATEGORY:
      return {
        ...state,
        deleteEBookCategoryStatus: true,
        eBookCategories: state.eBookCategories.filter((item) => {
          return item["_id"] !== action.payload;
        }),
      };
    case SET_DELETE_EBOOK_CATEGORY_STATUS:
      return {
        ...state,
        deleteEBookCategoryStatus: false,
      };

    case GET_EBOOK_CATEGORIES:
      return {
        ...state,
        eBookCategories: action.payload,
      };
    case GET_EBOOK_RELEVANT_DATA:
      return {
        ...state,
        eBookRelevantData: action.payload,
      };
    case ADD_EBOOK:
      return {
        ...state,
        addEBookStatus: true,
        eBooks: [action.payload, ...state.eBooks],
      };
    case SET_ADD_EBOOK_STATUS:
      return {
        ...state,
        addEBookStatus: false,
      };
    case ADD_EBOOK_ERROR:
      return {
        ...state,
        addEBookError: action.payload,
      };
    case SET_ADD_EBOOK_ERROR_STATUS:
      return {
        ...state,
        addEBookError: null,
      };
    case CHANGE_EBOOKS_PERMISSION:
      return {
        ...state,
        changeEBooksPermissionStatus: true,
        eBooks: state.eBooks.map((eBook) =>
          action.payload.hasOwnProperty(eBook._id)
            ? action.payload[eBook._id]
            : eBook
        ),
      };
    case SET_EBOOKS_PERMISSION_STATUS:
      return {
        ...state,
        changeEBooksPermissionStatus: false,
      };
    case DELETE_EBOOK:
      return {
        ...state,
        deleteEBookStatus: true,
        eBooks: state.eBooks.filter((eBook) => {
          return eBook["_id"] !== action.payload;
        }),
      };
    case SET_DELETE_EBOOK_STATUS:
      return {
        ...state,
        deleteEBookStatus: false,
      };
    case EDIT_EBOOK:
      return {
        ...state,
        editEBookStatus: true,
        eBooks: state.eBooks.map((item) =>
          item["_id"] === action.payload["_id"] ? action.payload : item
        ),
      };
    case SET_EDIT_EBOOK_STATUS:
      return {
        ...state,
        editEBookStatus: false,
      };

    case ADD_EBOOK_COMMENT:
      return {
        ...state,
        eBook: action.payload,
      };
    case DELETE_EBOOK_COMMENT:
      return {
        ...state,
        eBook: {
          ...state.eBook,
          comments: state.eBook.comments.filter((comment) => {
            return comment["_id"] !== action.payload.commentId;
          }),
        },
      };
    case EDIT_EBOOK_COMMENT:
      return {
        ...state,
        editEBookCommentStatus: true,
        eBook: {
          ...state.eBook,
          comments: state.eBook.comments.map((comment) =>
            comment["_id"] !== action.payload.commentId
              ? comment
              : action.payload.data
          ),
        },
      };
    case SET_EDIT_EBOOK_COMMENT_STATUS:
      return {
        ...state,
        editEBookCommentStatus: false,
      };

    default:
      return state;
  }
}
