import { GET_DOWNLOAD_FILE } from "../actions/types";

const initialState = {
  downloadFile: { coupon_codes: [], cpas: [] },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DOWNLOAD_FILE:
      let tempData = {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          [action.payload.type]: action.payload.data,
        },
      };
      return tempData;

    default:
      return state;
  }
}
