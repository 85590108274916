import {
    CREATE_COMMERCE_BOOK,
    GET_COMMERCE_BOOKS,
    EDIT_COMMERCE_BOOK,
    COMMERCE_ERROR,
    DELETE_BOOK,
    DELETE_BOOK_STATUS,
    ADD_BOOK_STATUS,
    EDIT_BOOK_STATUS,
    BOOK_LOADING,
    DELETE_BOOKS,
    CREATE_BOOK_CATEGORY,
    CATEGORY_STATUS,
    EDIT_BOOK_CATEGORY,
    GET_BOOK_CATEGORY,
    BOOK_DETAIL,
    ADD_BOOK_COMMENT,
    DELETE_BOOK_COMMENT,
    GET_COMMERCE_BOOK_RELEVANT_DATA
} from '../actions/types'

const initialState = {
    commerceBooks : [],
    commerceError : "",
    addBookState : false,
    deleteBookState : false,
    bookLoading : false,
    categoryStatus : false,
    bookDetail : {},
    bookRelevantData : {}
}


export default function(state = initialState , actions) {
    switch(actions.type) {
        case BOOK_LOADING : 
            return {
                ...state,
                bookLoading : true
            }
        case CREATE_COMMERCE_BOOK :
            return {
                ...state,
                commerceBooks : actions.payload,
                addBookState : true,
                bookLoading : false
            }
        case GET_COMMERCE_BOOKS : 
            return {
                ...state,
                commerceBooks : actions.payload,
                bookLoading : false
            }
        case COMMERCE_ERROR : 
            return {
                ...state,
                commerceError : actions.payload
            }
        case EDIT_COMMERCE_BOOK : 
            return {
                ...state,
                commerceBooks : actions.payload,
                editBookStatus : true,
                bookLoading : false
            }
        case DELETE_BOOK :
            return {
                ...state ,
                deleteBookState : true,
                commerceBooks : actions.payload
            }
        case DELETE_BOOKS :
            return {
                ...state ,
                deleteBookState : true,
                commerceBooks : actions.payload
            }
        case DELETE_BOOK_STATUS : 
            return {
                ...state,
                deleteBookState : false
            }
        case ADD_BOOK_STATUS :
            return {
                ...state,
                addBookState : false
            }
        case EDIT_BOOK_STATUS :
            return {
                ...state,
                editBookStatus : false
            }
        case CREATE_BOOK_CATEGORY : 
            return  {
                ...state,
                categoryStatus : true,
                categoryList : actions.payload
            }
        case CATEGORY_STATUS : 
            return {
                ...state,
                categoryStatus : false
            }
        case EDIT_BOOK_CATEGORY : 
            return {
                ...state,
                categoryStatus : true,
                categoryList : actions.payload
            }
        case GET_BOOK_CATEGORY : 
            return {
                ...state,
                categoryList : actions.payload
            }
        case BOOK_DETAIL :
            return {
                ...state,
                bookDetail : actions.payload
            }
        case ADD_BOOK_COMMENT : 
            return {
                ...state ,
                bookDetail : actions.payload
            }
        case DELETE_BOOK_COMMENT : 
            return {
                ...state,
                bookDetail : actions.payload
            }
        case GET_COMMERCE_BOOK_RELEVANT_DATA :
            return {
                ...state,
                bookRelevantData : actions.payload
            }
        default :
            return state;
    }
}