import {
    GET_TAGS,
    TAGS_ERROR,
    GET_TAG_LIST,
    UPDATE_SEQ,
} from '../actions/types'

const initialState = {
    tags : [],
    bookList : [],
    goodsList : [],
    ebookList : [],
    webtoonList : [],
}

export default function(state = initialState , actions) {
    switch(actions.type) {
        case GET_TAGS :
            return {
                ...state ,
                tags : actions.payload
            }
        case GET_TAG_LIST :
            return  {
                ...state,
                bookList : actions.payload.books,
                goodsList : actions.payload.goods,
                ebookList : actions.payload.ebook,
                webtoonList : actions.payload.webtoon,
            }
        case UPDATE_SEQ : 
            return {
                ...state
            }

        default : {
            return state;
        }
    }
}