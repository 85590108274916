import {
  GENERAL_POINT_HISTORIES_LOADING,
  GET_GENERAL_POINT_HISTORIES,
  GET_POINT_HISTORIES_WITH_DATE,
  POINT_HISTORIES_WITH_DATE_LOADING,
} from "../actions/types";

const initialState = {
  generalPointHistoriesLoading: false,
  generalPointHistories: [],
  pointHistoriesWithDateLoading: false,
  pointHistoriesWithDate: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GENERAL_POINT_HISTORIES_LOADING:
      return {
        ...state,
        generalPointHistoriesLoading: true,
      };
    case GET_GENERAL_POINT_HISTORIES:
      return {
        ...state,
        generalPointHistoriesLoading: false,
        generalPointHistories: action.payload,
      };
    case POINT_HISTORIES_WITH_DATE_LOADING:
      return {
        ...state,
        pointHistoriesWithDateLoading: true,
      };
    case GET_POINT_HISTORIES_WITH_DATE:
      return {
        ...state,
        pointHistoriesWithDateLoading: false,
        pointHistoriesWithDate: action.payload,
      };
    default:
      return state;
  }
}
