import axios from "axios";

export const convertToReadableDate = (type, data) => {
  let monthObj = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  let temp1 = new Date(data).toString();

  const temp2 = temp1.split(" ");
  let extra = "";
  if (type == 0) {
    extra = "/" + temp2[4];
  }

  let temp3 = temp2[3] + "/" + monthObj[temp2[1]] + "/" + temp2[2] + extra;
  return temp3;
};

const publicVapidKey = localStorage.getItem("REACT_APP_PUBLICVAPIDKEY");

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const sendNotification = async (token, info) => {
  const register = await navigator.serviceWorker.register("/worker.js", {
    scope: "/",
  });

  const subscription = await register.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
  });

  fetch("/node/api/admin/pushnotification/sendnotification", {
    method: "POST",
    body: JSON.stringify({ subscription, info }),
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  })
    .then(() => console.log("successfully sent notification"))
    .catch((err) => console.log("err", err));
};

export const base64ToBlob = function (base64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(base64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });

  return blob;
};
