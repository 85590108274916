import {
  ADD_WEB_TOON_CATEGORY,
  SET_ADD_WEB_TOON_CATEGORY_STATUS,
  ADD_WEB_TOON_CATEGORY_ERROR,
  SET_ADD_WEB_TOON_CATEGORY_ERROR_STATUS,
  GET_WEB_TOON_CATEGORIES,
  ADD_WEB_TOON,
  SET_ADD_WEB_TOON_STATUS,
  ADD_WEB_TOON_ERROR,
  SET_ADD_WEB_TOON_ERROR_STATUS,
  GET_WEB_TOONS,
  EDIT_WEB_TOON,
  SET_EDIT_WEB_TOON_STATUS,
  GET_WEB_TOON_RELEVANT_DATA,
  CHANGE_WEB_TOONS_PERMISSION,
  SET_WEB_TOONS_PERMISSION_STATUS,
  DELETE_WEB_TOON,
  SET_DELETE_WEB_TOON_STATUS,
  GET_WEB_TOON,
  ADD_WEB_TOON_COMMENT,
  DELETE_WEB_TOON_COMMENT,
  EDIT_WEB_TOON_COMMENT,
  SET_EDIT_WEB_TOON_COMMENT_STATUS,
  EDIT_WEB_TOON_CATEGORY,
  SET_EDIT_WEB_TOON_CATEGORY_STATUS,
  DELETE_WEB_TOON_CATEGORY,
  SET_DELETE_WEB_TOON_CATEGORY_STATUS,
} from "../actions/types";

const initialState = {
  webToon: {},
  webToons: [],
  addWebToonStatus: false,
  editWebToonStatus: false,
  addWebToonError: null,
  addWebToonCategoryStatus: false,
  addWebToonCategoryError: null,
  webToonCategories: [],
  webToonRelevantData: [],
  changeWebToonsPermissionStatus: false,
  deleteWebToonStatus: false,
  editWebToonCommentStatus: false,
  editWebToonCategoryStatus: false,
  deleteWebToonCategoryStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WEB_TOONS:
      return {
        ...state,
        webToons: action.payload,
      };
    case GET_WEB_TOON:
      return {
        ...state,
        webToon: action.payload,
      };

    case ADD_WEB_TOON:
      return {
        ...state,
        addWebToonStatus: true,
        webToons: [action.payload, ...state.webToons],
      };
    case SET_ADD_WEB_TOON_STATUS:
      return {
        ...state,
        addWebToonStatus: false,
      };
    case ADD_WEB_TOON_ERROR:
      return {
        ...state,
        addWebToonError: action.payload,
      };
    case SET_ADD_WEB_TOON_ERROR_STATUS:
      return {
        ...state,
        addWebToonError: null,
      };
    case EDIT_WEB_TOON:
      return {
        ...state,
        editWebToonStatus: true,
        webToons: state.webToons.map((item) =>
          item["_id"] === action.payload["_id"] ? action.payload : item
        ),
      };
    case SET_EDIT_WEB_TOON_STATUS:
      return {
        ...state,
        editWebToonStatus: false,
      };

    case ADD_WEB_TOON_CATEGORY:
      return {
        ...state,
        addWebToonCategoryStatus: true,
        webToonCategories: [...state.webToonCategories, action.payload],
      };
    case EDIT_WEB_TOON_CATEGORY:
      return {
        ...state,
        editWebToonCategoryStatus: true,
        webToonCategories: state.webToonCategories.map((item) =>
          item["_id"] === action.payload["_id"] ? action.payload : item
        ),
      };
    case DELETE_WEB_TOON_CATEGORY:
      return {
        ...state,
        deleteWebToonCategoryStatus: true,
        webToonCategories: state.webToonCategories.filter((item) => {
          return item["_id"] !== action.payload;
        }),
      };
    case SET_DELETE_WEB_TOON_CATEGORY_STATUS:
      return {
        ...state,
        deleteWebToonCategoryStatus: false,
      };

    case SET_EDIT_WEB_TOON_CATEGORY_STATUS:
      return {
        ...state,
        editWebToonCategoryStatus: false,
      };
    case SET_ADD_WEB_TOON_CATEGORY_STATUS:
      return {
        ...state,
        addWebToonCategoryStatus: false,
      };
    case ADD_WEB_TOON_CATEGORY_ERROR:
      return {
        ...state,
        addWebToonCategoryError: action.payload,
      };
    case SET_ADD_WEB_TOON_CATEGORY_ERROR_STATUS:
      return {
        ...state,
        addWebToonCategoryError: null,
      };
    case GET_WEB_TOON_CATEGORIES:
      return {
        ...state,
        webToonCategories: action.payload,
      };
    case GET_WEB_TOON_RELEVANT_DATA:
      return {
        ...state,
        webToonRelevantData: action.payload,
      };

    case CHANGE_WEB_TOONS_PERMISSION:
      return {
        ...state,
        changeWebToonsPermissionStatus: true,
        webToons: state.webToons.map((webToon) =>
          action.payload.hasOwnProperty(webToon._id)
            ? action.payload[webToon._id]
            : webToon
        ),
      };
    case SET_WEB_TOONS_PERMISSION_STATUS:
      return {
        ...state,
        changeWebToonsPermissionStatus: false,
      };
    case DELETE_WEB_TOON:
      return {
        ...state,
        deleteWebToonStatus: true,
        webToons: state.webToons.filter((webToon) => {
          return webToon["_id"] !== action.payload;
        }),
      };
    case SET_DELETE_WEB_TOON_STATUS:
      return {
        ...state,
        deleteWebToonStatus: false,
      };
    case ADD_WEB_TOON_COMMENT:
      return {
        ...state,
        webToon: action.payload,
      };
    case DELETE_WEB_TOON_COMMENT:
      return {
        ...state,
        webToon: {
          ...state.webToon,
          comments: state.webToon.comments.filter((comment) => {
            return comment["_id"] !== action.payload.commentId;
          }),
        },
      };
    case EDIT_WEB_TOON_COMMENT:
      return {
        ...state,
        editWebToonCommentStatus: true,
        webToon: {
          ...state.webToon,
          comments: state.webToon.comments.map((comment) =>
            comment["_id"] !== action.payload.commentId
              ? comment
              : action.payload.data
          ),
        },
      };
    case SET_EDIT_WEB_TOON_COMMENT_STATUS:
      return {
        ...state,
        editWebToonCommentStatus: false,
      };

    default:
      return state;
  }
}
