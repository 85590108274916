// For admin users
export const INIT_APPLICATION = "INIT_APPLICATION";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_FAIL_FIREBASE = "LOGIN_FAIL_FIREBASE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_FAIL_FIREBASE = "REGISTER_FAIL_FIREBASE";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//For level settings
export const GET_LEVEL_SETTINGS = "GET_LEVEL_SETTINGS";
export const LEVEL_SETTINGS_LOADING = "LEVEL_SETTINGS_LOADING";
export const EDIT_LEVEL_SETTING = "EDIT_LEVEL_SETTING";
export const ADD_AVATAR_LEVEL_SETTING = "ADD_AVATAR_LEVEL_SETTING";
export const EDIT_AVATAR_LEVEL_SETTING = "EDIT_AVATAR_LEVEL_SETTING";

//For special avatars
export const GET_SPECIAL_AVATARS = "GET_SPECIAL_AVATARS";
export const ADD_SPECIAL_AVATARS = "ADD_SPECIAL_AVATARS";
export const ADD_SPECIAL_AVATARS_STATUS = "ADD_SPECIAL_AVATARS_STATUS";
export const EDIT_SPECIAL_AVATARS = "EDIT_SPECIAL_AVATARS";
export const EDIT_SPECIAL_AVATARS_STATUS = "EDIT_SPECIAL_AVATARS_STATUS";

// For test app users
export const GET_TEST_APP_USERS = "GET_TEST_APP_USERS";
export const GET_TEST2_APP_USERS = "GET_TEST2_APP_USERS";
export const END_GET_TEST2_APP_USERS_STATUS = "END_GET_TEST2_APP_USERS_STATUS";

// For app users
export const GET_APP_USERS = "GET_APP_USERS";
export const SET_GET_APP_USERS_STATUS = "SET_GET_APP_USERS_STATUS";
export const APP_USERS_LOADING = "APP_USERS_LOADING";
export const GET_APP_USER = "GET_APP_USER";
export const APP_USER_LOADING = "APP_USER_LOADING";
export const EDIT_APP_USER = "EDIT_APP_USER";
export const CLEAR_APP_USER = "CLEAR_APP_USER";
export const APP_USER_REGISTER_SUCCESS = "APP_USER_REGISTER_SUCCESS";
export const GET_APP_USER_BLOG_POSTING_COMMENTS = "GET_APP_USER_BLOG_POSTING_COMMENTS";
export const GET_APP_USER_MAP_POSTING_COMMENTS = "GET_APP_USER_MAP_POSTING_COMMENTS";
export const ADD_USERS_SPECIAL_CHARACTERS = "ADD_USERS_SPECIAL_CHARACTERS";
export const ADD_USERS_SPECIAL_CHARACTERS_STATUS = "ADD_USERS_SPECIAL_CHARACTERS_STATUS";
export const DELETE_APP_USER = "DELETE_APP_USER";
export const DELETE_APP_USER_STATUS = "DELETE_APP_USER_STATUS";
export const ADD_USERS_POINTS = "ADD_USERS_POINTS";
export const ADD_USERS_POINTS_STATUS = "ADD_USERS_POINTS_STATUS";
export const CHANGE_USERS_LEVEL = "CHANGE_USERS_LEVEL";
export const CHANGE_USERS_LEVEL_STATUS = "CHANGE_USERS_LEVEL_STATUS";
export const GET_APP_USERS_LEVEL_DISTRIBUTION = "GET_APP_USERS_LEVEL_DISTRIBUTION";
export const GET_USER_POINT_HISTORIES_WITH_DATE = "GET_USER_POINT_HISTORIES_WITH_DATE";
export const SEND_USERS_MESSAGE = "SEND_USERS_MESSAGE";
export const SEND_USERS_MESSAGES_STATUS = "SEND_USERS_MESSAGES_STATUS";
export const SEND_ALL_USERS_MESSAGE = "SEND_ALL_USERS_MESSAGE";
export const SEND_ALL_USERS_MESSAGES_STATUS = "SEND_ALL_USERS_MESSAGES_STATUS";

export const END_GET_APP_USERS_STATUS = "END_GET_APP_USERS_STATUS";
export const GET_DAILY_NEW_USERS_WITH_DATE = "GET_DAILY_NEW_USERS_WITH_DATE";
export const GET_CUMULATIVE_USERS_WITH_DATE = "GET_CUMULATIVE_USERS_WITH_DATE";
export const GET_TEMP_DETAIL = "GET_TEMP_DETAIL";
export const GET_CUMULATIVE_DOWNLOADS_WITH_DATE = "GET_CUMULATIVE_DOWNLOADS_WITH_DATE";
export const GET_CUMULATIVE_APPLE_DOWNLOADS_WITH_DATE = "GET_CUMULATIVE_APPLE_DOWNLOADS_WITH_DATE";
export const GET_DAU_WITH_DATE = "GET_DAU_WITH_DATE";
export const SET_DAU_DATES = "SET_DAU_DATES";
export const GET_MAU_WITH_DATE = "GET_MAU_WITH_DATE";
export const GET_APP_USERS_COUNT = "GET_APP_USERS_COUNT";
export const GET_MONGO_APP_USERS = "GET_MONGO_APP_USERS";
export const SET_MONGO_SEARCHED = "SET_MONGO_SEARCHED";
export const SET_MONGO_APP_USERS_LOADING = "SET_MONGO_APP_USERS_LOADING";
export const SET_MONGO_APP_USERS_TYPE = "SET_MONGO_APP_USERS_TYPE";
export const GET_APP_USERS_DOWNLOAD_FILE = "GET_APP_USERS_DOWNLOAD_FILE";

// For avatars
export const ADD_AVATAR = "ADD_AVATAR";
export const CHANGE_ADD_AVATAR_STATUS = "CHANGE_ADD_AVATAR_STATUS";
export const GET_AVATARS = "GET_AVATARS";
export const AVATARS_LOADING = "AVATARS_LOADING";

// For blog postings
export const BLOG_LOADING = "BLOG_LOADING";
export const BLOG_ERROR = "BLOG_ERROR";
export const GET_BLOG_POSTLIST = "GET_BLOG_POSTLIST";
export const CREATE_BLOG_POST = "CREATE_BLOG_POST";
export const UPDATE_BLOG_POST = "UPDATE_BLOG_POST";
export const INIT_DETAIL_BLOG_POST = "INIT_DETAIL_BLOG_POST";
export const GET_BLOG_POST_COMMENT = "GET_BLOG_POST_COMMENT";
export const ADD_BLOG_POST_COMMENT = "ADD_BLOG_POST_COMMENT";
export const EDIT_BLOG_POST_COMMENT = "EDIT_BLOG_POST_COMMENT";
export const DELETE_BLOG_POST_COMMENT = "DELETE_BLOG_POST_COMMENT";
export const ADD_BLOG_POST_STATUS = "ADD_BLOG_POST_STATUS";
export const EDIT_BLOG_POST_STATUS = "EDIT_BLOG_POST_STATUS";
export const DELETE_BLOG_POST = "DELETE_BLOG_POST";
export const DELETE_BLOG_POST_STATUS = "DELETE_BLOG_POST_STATUS";
export const SET_GET_BLOG_POSTINGS_STATUS = "SET_GET_BLOG_POSTINGS_STATUS";

// For test blog fan postings
export const GET_TEST_BLOG_FAN_POSTLIST = "GET_TEST_BLOG_FAN_POSTLIST";
export const END_GET_TEST_BLOG_FAN_POSTLIST_STATUS = "END_GET_TEST_BLOG_FAN_POSTLIST_STATUS";

// For blog fan postings
export const BLOG_FAN_LOADING = "BLOG_FAN_LOADING";
export const BLOG_FAN_ERROR = "BLOG_FAN_ERROR";
export const GET_BLOG_FAN_POSTLIST = "GET_BLOG_FAN_POSTLIST";
export const CREATE_BLOG_FAN_POST = "CREATE_BLOG_FAN_POST";
export const UPDATE_BLOG_FAN_POST = "UPDATE_BLOG_FAN_POST";
export const INIT_DETAIL_BLOG_FAN_POST = "INIT_DETAIL_BLOG_FAN_POST";
export const GET_BLOG_FAN_POST_COMMENT = "GET_BLOG_FAN_POST_COMMENT";
export const ADD_BLOG_FAN_POST_COMMENT = "ADD_BLOG_FAN_POST_COMMENT";
export const EDIT_BLOG_FAN_POST_COMMENT = "EDIT_BLOG_FAN_POST_COMMENT";
export const DELETE_BLOG_FAN_POST_COMMENT = "DELETE_BLOG_FAN_POST_COMMENT";
export const ADD_BLOG_FAN_POST_STATUS = "ADD_BLOG_FAN_POST_STATUS";
export const EDIT_BLOG_FAN_POST_STATUS = "EDIT_BLOG_FAN_POST_STATUS";
export const DELETE_BLOG_FAN_POST = "DELETE_BLOG_FAN_POST";
export const DELETE_BLOG_FAN_POST_STATUS = "DELETE_BLOG_FAN_POST_STATUS";
export const DELETE_BLOG_FAN_POSTS = "DELETE_BLOG_FAN_POSTS";
export const DELETE_BLOG_FAN_POSTS_STATUS = "DELETE_BLOG_FAN_POSTS_STATUS";
export const CHANGE_BLOG_FAN_POSTS_PERMISSION = "CHANGE_BLOG_FAN_POSTS_PERMISSION";
export const CHANGE_BLOG_FAN_POSTS_PERMISSION_STATUS = "CHANGE_BLOG_FAN_POSTS_PERMISSION_STATUS";
export const GET_BLOG_FAN_POST = "GET_BLOG_FAN_POST";
export const SET_GET_BLOG_FAN_POSTINGS_STATUS = "SET_GET_BLOG_FAN_POSTINGS_STATUS";
export const GET_BLOG_FAN_POSTS_APP_USERS = "GET_BLOG_FAN_POSTS_APP_USERS";
export const END_GET_BLOG_FAN_POSTS_APP_USERS_STATUS = "END_GET_BLOG_FAN_POSTS_APP_USERS_STATUS";
export const END_GET_BLOG_FAN_POSTLIST_STATUS = "END_GET_BLOG_FAN_POSTLIST_STATUS";
export const GET_BLOG_FAN_MONGO_POSTLIST = "GET_BLOG_FAN_MONGO_POSTLIST";
export const GET_FAN_BLOG_LIST_COUNT = "GET_FAN_BLOG_LIST_COUNT";
export const SET_FAN_BLOG_LIST_COUNT = "SET_FAN_BLOG_LIST_COUNT";
export const SEARCH_BLOG_FAN_POSTING_LIST = "SEARCH_BLOG_FAN_POSTING_LIST";
export const SET_SEARCH_BLOG_FAN_POSTING_STATUS = "SET_SEARCH_BLOG_FAN_POSTING_STATUS";
export const SET_BLOG_FAN_MONGO_POSTLIST = "SET_BLOG_FAN_MONGO_POSTLIST";

// For video
export const VIDEO_LOADING = "VIDEO_LOADING";
export const VIDEO_ERROR = "VIDEO_ERROR";
export const GET_VIDEO_CATEGORIES = "GET_VIDEO_CATEGORIES";
export const CREATE_VIDEO_CATEGORY = "CREATE_VIDEO_CATEGORY";
export const UPDATE_VIDEO_CATEGORY1 = "UPDATE_VIDEO_CATEGORY1";
export const UPDATE_VIDEO_CATEGORY2 = "UPDATE_VIDEO_CATEGORY2";
export const GET_VIDEO_POSTLIST = "GET_VIDEO_POSTLIST";
export const CREATE_VIDEO_POST = "CREATE_VIDEO_POST";
export const UPDATE_VIDEO_POST = "UPDATE_VIDEO_POST";
export const INIT_DETAIL_VIDEO_POST = "INIT_DETAIL_VIDEO_POST";
export const GET_VIDEO_POST_COMMENT = "GET_VIDEO_POST_COMMENT";
export const ADD_VIDEO_POST_COMMENT = "ADD_VIDEO_POST_COMMENT";
export const EDIT_VIDEO_POST_COMMENT = "EDIT_VIDEO_POST_COMMENT";
export const GET_VIDEO_POST_COMMENT_QUESTION = "GET_VIDEO_POST_COMMENT_QUESTION";
export const ADD_VIDEO_POST_COMMENT_QUESTION = "ADD_VIDEO_POST_COMMENT_QUESTION";
export const EDIT_VIDEO_POST_COMMENT_QUESTION = "EDIT_VIDEO_POST_COMMENT_QUESTION";
export const DELETE_VIDEO_POST_COMMENT = "DELETE_VIDEO_POST_COMMENT";
export const DELETE_VIDEO_POST_COMMENT_QUESTION = "DELETE_VIDEO_POST_COMMENT_QUESTION";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const DELETE_VIDEO_POST = "DELETE_VIDEO_POST";
export const DELETE_VIDEO_POST_STATUS = "DELETE_VIDEO_POST_STATUS";
export const GET_VIDEO_TOKEN = "GET_VIDEO_TOKEN";
export const GET_SORT_VIDEO_LIST = "GET_SORT_VIDEO_LIST";
export const UPDATE_SORT_VIDEO_LIST = "UPDATE_SORT_VIDEO_LIST";
export const SET_SORT_VIDEO_STATUS = "SET_SORT_VIDEO_STATUS";

// For map
export const MAP_LOADING = "MAP_LOADING";
export const MAP_ERROR = "MAP_ERROR";
export const GET_MAP_POSTLIST = "GET_MAP_POSTLIST";
export const CREATE_MAP_POST = "CREATE_MAP_POST";
export const UPDATE_MAP_POST = "UPDATE_MAP_POST";
export const INIT_DETAIL_MAP_POST = "INIT_DETAIL_MAP_POST";
export const GET_MAP_POST_COMMENT = "GET_MAP_POST_COMMENT";
export const ADD_MAP_POST_COMMENT = "ADD_MAP_POST_COMMENT";
export const EDIT_MAP_POST_COMMENT = "EDIT_MAP_POST_COMMENT";
export const GET_MAP_POST_COMMENT_QUESTION = "GET_MAP_POST_COMMENT_QUESTION";
export const ADD_MAP_POST_COMMENT_QUESTION = "ADD_MAP_POST_COMMENT_QUESTION";
export const EDIT_MAP_POST_COMMENT_QUESTION = "EDIT_MAP_POST_COMMENT_QUESTION";
export const DELETE_MAP_POST_COMMENT = "DELETE_MAP_POST_COMMENT";
export const DELETE_MAP_POST_COMMENT_QUESTION = "DELETE_MAP_POST_COMMENT_QUESTION";
export const ADD_MAP_POST_STATUS = "ADD_MAP_POST_STATUS";
export const EDIT_MAP_POST_STATUS = "EDIT_MAP_POST_STATUS";
export const DELETE_MAP_POST = "DELETE_MAP_POST";
export const DELETE_MAP_POST_STATUS = "DELETE_MAP_POST_STATUS";
export const DELETE_MAP_POSTS_STATUS = "DELETE_MAP_POSTS_STATUS";
export const CHANGE_MAP_POSTS_PERMISSION = "CHANGE_MAP_POSTS_PERMISSION";
export const CHANGE_MAP_POSTS_PERMISSION_STATUS = "CHANGE_MAP_POSTS_PERMISSION_STATUS";
export const SET_GET_MAP_POSTINGS_STATUS = "SET_GET_MAP_POSTINGS_STATUS";

// For commerce
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const GET_PRODUCT = "GET_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const ADD_PRODUCT_STATUS = "ADD_PRODUCT_STATUS";
export const EDIT_PRODUCT_STATUS = "EDIT_PRODUCT_STATUS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_STATUS = "DELETE_PRODUCT_STATUS";
export const DELETE_MAP_POSTS = "DELETE_MAP_POSTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const DELETE_PRODUCTS_STATUS = "DELETE_PRODUCTS_STATUS";
export const SET_GET_PRODUCTS_STATUS = "SET_GET_PRODUCTS_STATUS";
export const GET_COMMERCE_PRODUCT = "GET_COMMERCE_PRODUCT";
export const SELECT_COMMERCE_PRODUCT = "SELECT_COMMERCE_PRODUCT";
export const SET_COMMERCE_PURCHASE_INITIAL = "SET_COMMERCE_PURCHASE_INITIAL";
export const SET_CONFIRM_NEW_ONES_STATUS = "SET_CONFIRM_NEW_ONES_STATUS";

// For quizzes
export const GET_QUIZZES = "GET_QUIZZES";
export const QUIZZES_LOADING = "QUIZZES_LOADING";
export const QUIZ_LOADING = "QUIZ_LOADING";
export const GET_QUIZ = "GET_QUIZ";
export const ADD_QUIZ = "ADD_QUIZ";
export const ADD_QUIZ_STATUS = "ADD_QUIZ_STATUS";
export const ADD_QUIZ_SET = "ADD_QUIZ_SET";
export const ADD_QUIZ_SET_STATUS = "ADD_QUIZ_SET_STATUS";
export const EDIT_QUIZ = "EDIT_QUIZ";
export const EDIT_QUIZ_STATUS = "EDIT_QUIZ_STATUS";
export const SET_GET_QUIZZES_STATUS = "SET_GET_QUIZZES_STATUS";
export const GET_DAILY_QUIZ_PARTICIPANTS_WITH_DATE = "GET_DAILY_QUIZ_PARTICIPANTS_WITH_DATE";
export const SET_DETAIL_QUIZZ_ID = "SET_DETAIL_QUIZZ_ID";
export const GET_DETAIL_QUIZ_COMMENT = "GET_DETAIL_QUIZ_COMMENT";
export const DELETE_QUIZ_COMMENT = "DELETE_QUIZ_COMMENT";
export const GET_TEMP_QUIZ = "GET_TEMP_QUIZ";

// For notice postings
export const NOTICE_POSTINGS_LOADING = "NOTICE_POSTINGS_LOADING";
export const GET_NOTICE_POSTINGS = "NOTICE_POSTINGGET_NOTICE_POSTINGSS_LOADING";
export const ADD_NOTICE_POSTING = "ADD_NOTICE_POSTING";
export const ADD_NOTICE_POSTING_STATUS = "ADD_NOTICE_POSTING_STATUS";
export const DELETE_NOTICE_POSTING = "DELETE_NOTICE_POSTING";
export const EDIT_NOTICE_POSTING = "EDIT_NOTICE_POSTING";
export const EDIT_NOTICE_POSTING_STATUS = "EDIT_NOTICE_POSTING_STATUS";
export const DELETE_NOTICE_POSTING_STATUS = "DELETE_NOTICE_POSTING_STATUS";
export const SET_GET_NOTICE_POSTINGS_STATUS = "SET_GET_NOTICE_POSTINGS_STATUS";
export const GET_NOTICE_CATEGORY_LIST = "GET_NOTICE_CATEGORY_LIST";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";

// For q and a
export const SET_QANDA_LIST = "SET_QANDA_LIST";
export const UPDATE_QANDA_LIST = "UPDATE_QANDA_LIST";
export const GET_QANDA_LIST_COUNT = "GET_QANDA_LIST_COUNT";

export const CHANGE_QANDA_STATUS = "CHANGE_QANDA_STATUS";
export const CHANGE_QANDA_STATUS_STATE = "CHANGE_QANDA_STATUS_STATE";

export const SET_QANDA_PROCESS = "SET_QANDA_PROCESS";
export const SET_QANDA_CURRENT_PAGE = "SET_QANDA_CURRENT_PAGE";
export const SET_QANDA_SEARCH_OBJ = "SET_QANDA_SEARCH_OBJ";
export const SET_QANDA_MAX_PAGE = "SET_QANDA_MAX_PAGE";

export const ADD_QANDA_COMMENT_STATUS = "ADD_QANDA_COMMENT_STATUS";
export const EDIT_QANDA_COMMENT = "EDIT_QANDA_COMMENT";
export const EDIT_QANDA_COMMENT_STATUS = "EDIT_QANDA_COMMENT_STATUS";

export const DELETE_QANDA = "DELETE_QANDA";
export const DELETE_QANDAS = "DELETE_QANDAS";
export const DELETE_QANDA_STATUS = "DELETE_QANDA_STATUS";
export const DELETE_QANDAS_STATUS = "DELETE_QANDAS_STATUS";
export const DELETE_QANDA_COMMENT = "DELETE_QANDA_COMMENT";

// For point histories
export const GENERAL_POINT_HISTORIES_LOADING = "GENERAL_POINT_HISTORIES_LOADING";
export const GET_POINT_HISTORIES = "GET_POINT_HISTORIES";
export const GET_GENERAL_POINT_HISTORIES = "GET_GENERAL_POINT_HISTORIES";
export const POINT_HISTORIES_WITH_DATE_LOADING = "POINT_HISTORIES_WITH_DATE_LOADING";
export const GET_POINT_HISTORIES_WITH_DATE = "GET_POINT_HISTORIES_WITH_DATE";

// For settings
// For quiz settings
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const GET_SETTINGS = "GET_SETTINGS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const EDIT_SETTINGS_STATUS = "EDIT_SETTINGS_STATUS";
export const CHANGE_QUIZ_SCHEDULER_STATUS = "CHANGE_QUIZ_SCHEDULER_STATUS";
export const CHANGE_QUIZ_SCHEDULER_STATUS_STATE = "CHANGE_QUIZ_SCHEDULER_STATUS_STATE";

// For reports
export const REPORTS_LOADING = "REPORTS_LOADING";
export const GET_REPORTS = "GET_REPORTS";
export const CHANGE_REPORTS_APPROVAL = "CHANGE_REPORTS_APPROVAL";
export const CHANGE_REPORTS_APPROVAL_STATUS = "CHANGE_REPORTS_APPROVAL_STATUS";
export const SET_GET_REPORTS_STATUS = "SET_GET_REPORTS_STATUS";

// For reports renewal
export const GET_REPORT = "GET_REPORT";
export const SET_REPORT_LIST = "SET_REPORT_LIST";
export const CHANGE_REPORTS_STATUS = "CHANGE_REPORTS_STATUS";
export const CHANGE_REPORTS_STATUS_STATE = "CHANGE_REPORTS_STATUS_STATE";
export const DELETE_REPORTS = "DELETE_REPORTS";
export const DELETE_REPORTS_STATUS = "DELETE_REPORTS_STATUS";
export const SET_REPORT_PROCESS = "SET_REPORT_PROCESS";
export const SET_REPORT_CURRENT_PAGE = "SET_REPORT_CURRENT_PAGE";
export const SET_REPORT_SEARCH_OBJ = "SET_REPORT_SEARCH_OBJ";
export const SET_REPORT_MAX_PAGE = "SET_REPORT_MAX_PAGE";
export const RESTORE_REPORTED_USERS = "RESTORE_REPORTED_USERS";
export const RESTORE_REPORTED_USERS_STATUS = "RESTORE_REPORTED_USERS_STATUS";

// For push notification
export const SET_PUSH_NOTIFICATION_1 = "SET_PUSH_NOTIFICATION_1";
export const GET_PUSH_NOTIFICATION_1 = "GET_PUSH_NOTIFICATION_1";
export const CONFIRM_NEW_ONES = "CONFIRM_NEW_ONES";
export const SET_SOCKET = "SET_SOCKET";
export const SET_SOCKET_ID = "SET_SOCKET_ID";
export const PUSH_NOTIFICATION_1_STATUS = "PUSH_NOTIFICATION_1_STATUS";
export const CHANGE_CURRENT_PUSH_STATE_COMMERCE = "CHANGE_CURRENT_PUSH_STATE_COMMERCE";
export const CHANGE_CURRENT_PUSH_STATE_QA = "CHANGE_CURRENT_PUSH_STATE_QA";
export const CHANGE_CURRENT_PUSH_STATE_REPORT = "CHANGE_CURRENT_PUSH_STATE_REPORT";

// For download
export const GET_DOWNLOAD_FILE = "GET_DOWNLOAD_FILE";

// For dashboard
export const GET_ALL_POSTINGS_WITH_DATE = "GET_ALL_POSTINGS_WITH_DATE";
export const SET_ALL_POSTINGS_PAGE = "SET_ALL_POSTINGS_PAGE";
export const SET_CHOSEN_DATE = "SET_CHOSEN_DATE";
export const GET_ALL_COMMENTS_WITH_DATE = "GET_ALL_COMMENTS_WITH_DATE";
export const SET_ALL_COMMENTS_PAGE = "SET_ALL_COMMENTS_PAGE";
export const GET_CUMULATIVE_VIDEO_DATA_WITH_DATE = "GET_CUMULATIVE_VIDEO_DATA_WITH_DATE";
export const GET_POPULAR_VIDEO_DATA_WITH_DATE = "GET_POPULAR_VIDEO_DATA_WITH_DATE";
export const GET_TOP_POPULAR_POSTING_DATA_WITH_DATE = "GET_TOP_POPULAR_POSTING_DATA_WITH_DATE";
export const GET_POPULAR_BLOG_FAN_POSTS_DATA_WITH_DATE = "GET_POPULAR_BLOG_FAN_POSTS_DATA_WITH_DATE";
export const SET_POPULAR_BLOG_FAN_POSTS_PAGE = "SET_POPULAR_BLOG_FAN_POSTS_PAGE";
export const GET_POPULAR_MAP_POSTS_DATA_WITH_DATE = "GET_POPULAR_MAP_POSTS_DATA_WITH_DATE";
export const SET_POPULAR_MAP_POSTS_PAGE = "SET_POPULAR_MAP_POSTS_PAGE";
export const GET_CONTENT_DASHBOARD_DOWNLOAD_FILE = "GET_CONTENT_DASHBOARD_DOWNLOAD_FILE";

//for commerce book
export const CREATE_COMMERCE_BOOK = "CREATE_COMMERCE_BOOK";
export const GET_COMMERCE_BOOKS = "GET_COMMERCE_BOOKS";
export const EDIT_COMMERCE_BOOK = "EDIT_COMMERCE_BOOK";
export const COMMERCE_ERROR = "COMMERCE_ERROR";
export const BOOK_LOADING = "BOOK_LOADING";
export const DELETE_BOOK = "DELETE_BOOK";
export const DELETE_BOOKS = "DELETE_BOOKS";
export const DELETE_BOOK_STATUS = "DELETE_BOOK_STATUS";
export const ADD_BOOK_STATUS = "ADD_BOOK_STATUS";
export const EDIT_BOOK_STATUS = "EDIT_BOOK_STATUS";
export const CREATE_BOOK_CATEGORY = "CREATE_BOOK_CATEGORY";
export const CATEGORY_STATUS = "CATEGORY_STATUS";
export const EDIT_BOOK_CATEGORY = "EDIT_BOOK_CATEGORY";
export const GET_BOOK_CATEGORY = "GET_BOOK_CATEGORY";
export const BOOK_DETAIL = "BOOK_DETAIL";
export const ADD_BOOK_COMMENT = "ADD_BOOK_COMMENT";
export const DELETE_BOOK_COMMENT = "DELETE_BOOK_COMMENT";
export const GET_COMMERCE_BOOK_RELEVANT_DATA = "GET_COMMERCE_BOOK_RELEVANT_DATA";

// For web toons
export const ADD_WEB_TOON_CATEGORY = "ADD_WEB_TOON_CATEGORY";
export const SET_ADD_WEB_TOON_CATEGORY_STATUS = "SET_ADD_WEB_TOON_CATEGORY_STATUS";
export const ADD_WEB_TOON_CATEGORY_ERROR = "ADD_WEB_TOON_CATEGORY_ERROR";
export const SET_ADD_WEB_TOON_CATEGORY_ERROR_STATUS = "SET_ADD_WEB_TOON_CATEGORY_ERROR_STATUS";
export const GET_WEB_TOON_CATEGORIES = "GET_WEB_TOON_CATEGORIES";
export const ADD_WEB_TOON = "ADD_WEB_TOON";
export const SET_ADD_WEB_TOON_STATUS = "SET_ADD_WEB_TOON_STATUS";
export const ADD_WEB_TOON_ERROR = "ADD_WEB_TOON_ERROR";
export const SET_ADD_WEB_TOON_ERROR_STATUS = "SET_ADD_WEB_TOON_ERROR_STATUS";
export const GET_WEB_TOONS = "GET_WEB_TOONS";
export const EDIT_WEB_TOON = "EDIT_WEB_TOON";
export const SET_EDIT_WEB_TOON_STATUS = "SET_EDIT_WEB_TOON_STATUS";
export const GET_WEB_TOON_RELEVANT_DATA = "GET_WEB_TOON_RELEVANT_DATA";
export const CHANGE_WEB_TOONS_PERMISSION = "CHANGE_WEB_TOONS_PERMISSION";
export const SET_WEB_TOONS_PERMISSION_STATUS = "SET_WEB_TOONS_PERMISSION_STATUS";
export const DELETE_WEB_TOON = "DELETE_WEB_TOON";
export const SET_DELETE_WEB_TOON_STATUS = "SET_DELETE_WEB_TOON_STATUS";
export const GET_WEB_TOON = "GET_WEB_TOON";
export const ADD_WEB_TOON_COMMENT = "ADD_WEB_TOON_COMMENT";
export const DELETE_WEB_TOON_COMMENT = "DELETE_WEB_TOON_COMMENT";
export const EDIT_WEB_TOON_COMMENT = "EDIT_WEB_TOON_COMMENT";
export const SET_EDIT_WEB_TOON_COMMENT_STATUS = "SET_EDIT_WEB_TOON_COMMENT_STATUS";
export const EDIT_WEB_TOON_CATEGORY = "EDIT_WEB_TOON_CATEGORY";
export const SET_EDIT_WEB_TOON_CATEGORY_STATUS = "SET_EDIT_WEB_TOON_CATEGORY_STATUS";
export const DELETE_WEB_TOON_CATEGORY = "DELETE_WEB_TOON_CATEGORY";
export const SET_DELETE_WEB_TOON_CATEGORY_STATUS = "SET_DELETE_WEB_TOON_CATEGORY_STATUS";

// For ebooks
export const ADD_EBOOK_CATEGORY = "ADD_EBOOK_CATEGORY";
export const ADD_EBOOK_CATEGORY_ERROR = "ADD_EBOOK_CATEGORY_ERROR";
export const SET_ADD_EBOOK_CATEGORY_STATUS = "SET_ADD_EBOOK_CATEGORY_STATUS";
export const SET_ADD_EBOOK_CATEGORY_ERROR_STATUS = "SET_ADD_EBOOK_CATEGORY_ERROR_STATUS";
export const GET_EBOOK_CATEGORIES = "GET_EBOOK_CATEGORIES";
export const GET_EBOOK_RELEVANT_DATA = "GET_EBOOK_RELEVANT_DATA";
export const ADD_EBOOK_ERROR = "ADD_EBOOK_ERROR";
export const ADD_EBOOK = "ADD_EBOOK";
export const SET_ADD_EBOOK_STATUS = "SET_ADD_EBOOK_STATUS";
export const SET_ADD_EBOOK_ERROR_STATUS = "SET_ADD_EBOOK_ERROR_STATUS";
export const GET_EBOOKS = "GET_EBOOKS";
export const CHANGE_EBOOKS_PERMISSION = "CHANGE_EBOOKS_PERMISSION";
export const SET_EBOOKS_PERMISSION_STATUS = "SET_EBOOKS_PERMISSION_STATUS";
export const DELETE_EBOOK = "DELETE_EBOOK";
export const SET_DELETE_EBOOK_STATUS = "SET_DELETE_EBOOK_STATUS";
export const EDIT_EBOOK = "EDIT_EBOOK";
export const SET_EDIT_EBOOK_STATUS = "SET_EDIT_EBOOK_STATUS";
export const GET_EBOOK = "GET_EBOOK";
export const ADD_EBOOK_COMMENT = "ADD_EBOOK_COMMENT";
export const DELETE_EBOOK_COMMENT = "DELETE_EBOOK_COMMENT";
export const EDIT_EBOOK_COMMENT = "EDIT_EBOOK_COMMENT";
export const SET_EDIT_EBOOK_COMMENT_STATUS = "SET_EDIT_EBOOK_COMMENT_STATUS";
export const SET_EBOOK_CONTENT = "SET_EBOOK_CONTENT";

// For Tags
export const GET_TAGS = "GET_TAGS";
export const TAGS_ERROR = "TAGS_ERROR";
export const UPDATE_SEQ = "UPDATE_SEQ";
export const GET_TAG_LIST = "GET_TAG_LIST";

// For commerce Goods
export const CREATE_COMMERCE_GOODS = "CREATE_COMMERCE_GOODS";
export const GET_COMMERCE_GOODS = "GET_COMMERCE_GOODS";
export const EDIT_COMMERCE_GOODS = "EDIT_COMMERCE_GOODS";
// export const COMMERCE_ERROR= "COMMERCE_ERROR";
export const GOODS_LOADING = "GOODS_LOADING";
export const DELETE_GOODS = "DELETE_GOODS";
export const DELETE_GOODSES = "DELETE_GOODSES";
export const DELETE_GOODS_STATUS = "DELETE_GOODS_STATUS";
export const ADD_GOODS_STATUS = "ADD_GOODS_STATUS";
export const EDIT_GOODS_STATUS = "EDIT_GOODS_STATUS";
export const CREATE_GOODS_CATEGORY = "CREATE_GOODS_CATEGORY";
// export const CATEGORY_STATUS = "CATEGORY_STATUS";
export const EDIT_GOODS_CATEGORY = "EDIT_GOODS_CATEGORY";
export const GET_GOODS_CATEGORY = "GET_GOODS_CATEGORY";
export const GOODS_DETAIL = "GOODS_DETAIL";
export const ADD_GOODS_COMMENT = "ADD_GOODS_COMMENT";
export const DELETE_GOODS_COMMENT = "DELETE_GOODS_COMMENT";
export const EDIT_EBOOK_CATEGORY = "EDIT_EBOOK_CATEGORY";
export const SET_EDIT_EBOOK_CATEGORY_STATUS = "SET_EDIT_EBOOK_CATEGORY_STATUS";
export const DELETE_EBOOK_CATEGORY = "DELETE_EBOOK_CATEGORY";
export const SET_DELETE_EBOOK_CATEGORY_STATUS = "SET_DELETE_EBOOK_CATEGORY_STATUS";
export const GET_COMMERCE_GOODS_RELEVANT_DATA = "GET_COMMERCE_GOODS_RELEVANT_DATA";

//For Commerce Purchase
export const GET_COMMERCE_PURCHASE = "GET_COMMERCE_PURCHASE";
export const GET_COMMERCE_PURCHASE_LIST_COUNT = "GET_COMMERCE_PURCHASE_LIST_COUNT";
export const SET_PURCHASE_DELIVERY_NUMBER = "SET_PURCHASE_DELIVERY_NUMBER";
export const SET_PURCHASE_DELIVERY_NUMBERS = "SET_PURCHASE_DELIVERY_NUMBERS";
export const SET_COMMERCE_PURCHASE_LIST = "SET_COMMERCE_PURCHASE_LIST";
export const CHANGE_COMMERCE_PURCHASE_STATUS = "CHANGE_COMMERCE_PURCHASE_STATUS";
export const SET_CHANGE_COMMERCE_PURCHASE_STATUS = "SET_CHANGE_COMMERCE_PURCHASE_STATUS";
export const ERROR_COMMERCE_PURCHASE = "ERROR_COMMERCE_PURCHASE";
export const SET_COMMERCE_PURCHASE_PROCESS = "SET_COMMERCE_PURCHASE_PROCESS";
export const SET_COMMERCE_PURCHASE_CURRENT_PAGE = "SET_COMMERCE_PURCHASE_CURRENT_PAGE";
export const SET_COMMERCE_SEARCH_OBJ = "SET_COMMERCE_SEARCH_OBJ";
export const SET_COMMERCE_PURCHASE_MAX_PAGE = "SET_COMMERCE_PURCHASE_MAX_PAGE";

//For Block History
export const SET_BLOCK_HISTORY = "SET_BLOCK_HISTORY";
export const ERROR_GET_BLOCK_HISTORY = "ERROR_GET_BLOCK_HISTORY";
export const SET_BLOCK_HISTORY_PROCESS = "SET_BLOCK_HISTORY_PROCESS";
export const SET_BLOCK_HISTORY_CURRENT_PAGE = "SET_BLOCK_HISTORY_CURRENT_PAGE";
export const SET_BLOCK_HISTORY_SEARCH_OBJ = "SET_BLOCK_HISTORY_SEARCH_OBJ";

//For Chat Novel
export const GET_CHAT_NOVEL = "GET_CHAT_NOVEL";
export const GET_CHAT_NOVEL_EPIC = "GET_CHAT_NOVEL_EPIC";
export const GET_EPIC_DOWNLOAD_FILE = "GET_EPIC_DOWNLOAD_FILE";

export const CHAT_NOVEL_DETAIL = "CHAT_NOVEL_DETAIL";
export const DELETE_CHAT_NOVEL_EPIC_COMMENT = "DELETE_CHAT_NOVEL_EPIC_COMMENT";
export const UPDATE_CHAT_NOVEL_EPIC = "UPDATE_CHAT_NOVEL_EPIC";
export const CHAT_NOVEL_ERROR = "CHAT_NOVEL_ERROR";

export const CHAT_NOVEL_STATE = "CHAT_NOVEL_STATE";
