import {
  PRODUCT_LOADING,
  GET_PRODUCT,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  ADD_PRODUCT_STATUS,
  EDIT_PRODUCT_STATUS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_STATUS,
  DELETE_PRODUCTS,
  DELETE_PRODUCTS_STATUS,
  SET_GET_PRODUCTS_STATUS,
  GET_COMMERCE_PRODUCT,
} from "../actions/types";

const initialState = {
  productLists: [],
  commerceProductList: [],
  productLoading: false,
  addProductStatus: false,
  editProductStatus: false,
  deleteProductStatus: false,
  deleteProductsStatus: false,
  getProductsStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        productLoading: true,
      };
    case GET_PRODUCT:
      return {
        ...state,
        productLoading: false,
        getProductsStatus: false,
        productLists: action.payload,
      };

    case SET_GET_PRODUCTS_STATUS:
      return {
        ...state,
        getProductsStatus: action.payload,
      };

    case ADD_PRODUCT:
      return {
        ...state,
        productLoading: false,
        addProductStatus: true,
        productLists: [...state.productLists, action.payload],
      };
    case ADD_PRODUCT_STATUS:
      return {
        ...state,
        addProductStatus: false,
      };
    case EDIT_PRODUCT:
      return {
        ...state,
        productLoading: false,
        editProductStatus: true,
        productLists: state.productLists.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    case EDIT_PRODUCT_STATUS:
      return {
        ...state,
        editProductStatus: false,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        deleteProductStatus: true,
        productLists: state.productLists.filter((item) => {
          return item["_id"] !== action.payload;
        }),
      };
    case DELETE_PRODUCT_STATUS:
      return {
        ...state,
        deleteProductStatus: false,
      };

    case DELETE_PRODUCTS:
      return {
        ...state,
        deleteProductsStatus: true,
        productLists: state.productLists.filter((product) => {
          return !action.payload.includes(product["_id"]);
        }),
      };
    case DELETE_PRODUCTS_STATUS:
      return {
        ...state,
        deleteProductsStatus: false,
      };
    case GET_COMMERCE_PRODUCT:
      return {
        ...state,
        productLoading: false,
        getProductsStatus: false,
        commerceProductList: action.payload,
      };

    default:
      return state;
  }
}
