import axios from "axios";
import firebase from "firebase/compat/app";
import {
  SET_PUSH_NOTIFICATION_1,
  GET_PUSH_NOTIFICATION_1,
  CONFIRM_NEW_ONES,
  SET_SOCKET,
  SET_SOCKET_ID,
  PUSH_NOTIFICATION_1_STATUS,
  CHANGE_CURRENT_PUSH_STATE_COMMERCE,
  CHANGE_CURRENT_PUSH_STATE_QA,
  CHANGE_CURRENT_PUSH_STATE_REPORT,
} from "./types";

export const setPushNotification1Status = (data) => async (dispatch, getState) => {
  dispatch({
    type: PUSH_NOTIFICATION_1_STATUS,
    payload: data,
  });
};

export const setPushNotification1 = (data) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;
  }
  const body = JSON.stringify(data);

  axios
    .post("/node_server/api/admin/pushnotification1", body, config)
    .then((res) => {
      console.log("Successfully set push notification 1");
      dispatch({
        type: SET_PUSH_NOTIFICATION_1,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const getPushNotification1 = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;
  }

  axios
    .get("/node_server/api/admin/pushnotification1", config)
    .then((res) => {
      console.log("Successfully retrieved push notification 1");
      dispatch({
        type: GET_PUSH_NOTIFICATION_1,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const confirmNewOnes = (data) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;
  }
  const body = JSON.stringify(data);

  axios
    .put("/node_server/api/admin/pushnotification1", body, config)
    .then((res) => {
      console.log(`Successfully confirmed new ${data.type}s`);
      data.moreItems = res.data;
      if (data.type == "commerce_purchase") {
        dispatch({
          type: CHANGE_CURRENT_PUSH_STATE_COMMERCE,
          payload: data,
        });
      }

      if (data.type == "q_and_a") {
        dispatch({
          type: CHANGE_CURRENT_PUSH_STATE_QA,
          payload: data,
        });
      }
      if (data.type == "report") {
        dispatch({
          type: CHANGE_CURRENT_PUSH_STATE_REPORT,
          payload: data,
        });
      }

      if (data.type)
        dispatch({
          type: CONFIRM_NEW_ONES,
          payload: data,
        });
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const setSocket = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_SOCKET,
    payload: data,
  });
};

export const setSocketId = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_SOCKET_ID,
    payload: data,
  });
};
