import {
  GET_APP_USERS,
  APP_USERS_LOADING,
  GET_APP_USER,
  APP_USER_LOADING,
  EDIT_APP_USER,
  CLEAR_APP_USER,
  APP_USER_REGISTER_SUCCESS,
  GET_APP_USER_BLOG_POSTING_COMMENTS,
  GET_APP_USER_MAP_POSTING_COMMENTS,
  ADD_USERS_SPECIAL_CHARACTERS,
  ADD_USERS_SPECIAL_CHARACTERS_STATUS,
  DELETE_APP_USER,
  DELETE_APP_USER_STATUS,
  ADD_USERS_POINTS,
  ADD_USERS_POINTS_STATUS,
  CHANGE_USERS_LEVEL,
  CHANGE_USERS_LEVEL_STATUS,
  GET_APP_USERS_LEVEL_DISTRIBUTION,
  GET_USER_POINT_HISTORIES_WITH_DATE,
  SEND_USERS_MESSAGE,
  SEND_USERS_MESSAGES_STATUS,
  GET_TEST_APP_USERS,
  GET_TEST2_APP_USERS,
  END_GET_TEST2_APP_USERS_STATUS,
  SET_GET_APP_USERS_STATUS,
  END_GET_APP_USERS_STATUS,
  GET_DAILY_NEW_USERS_WITH_DATE,
  GET_CUMULATIVE_USERS_WITH_DATE,
  GET_CUMULATIVE_DOWNLOADS_WITH_DATE,
  GET_DAU_WITH_DATE,
  SET_DAU_DATES,
  GET_MAU_WITH_DATE,
  GET_CUMULATIVE_APPLE_DOWNLOADS_WITH_DATE,
  SEND_ALL_USERS_MESSAGE,
  SEND_ALL_USERS_MESSAGES_STATUS,
  GET_APP_USERS_COUNT,
  GET_MONGO_APP_USERS,
  SET_MONGO_SEARCHED,
  SET_MONGO_APP_USERS_LOADING,
  SET_MONGO_APP_USERS_TYPE,
  GET_APP_USERS_DOWNLOAD_FILE,
} from "../actions/types";

const initialState = {
  appUsersLoading: false,
  getAppUsersStatus: false,
  appUsers: [],
  appUserLoading: false,
  appUser: {},
  appUserBlogPostingComments: [],
  appUserMapPostingComments: [],
  addUsersSpecialCharactersStatus: false,
  deleteAppUserStatus: false,
  addUsersPointsStatus: false,
  changeUsersLevelStatus: false,
  appUsersLevelDistribution: [],
  userPointHistoriesWithDate: {},
  sendUsersMessageStatus: false,
  sendAllUsersMessageCount: null,
  sendAllUsersMessageStatus: false,
  testAppUsers: [],
  test2AppUsers: [],
  getAppUsersStatus: false,
  dailyNewUsersWithDate: { data: [], dates: [] },
  cumulativeUsersWithDate: { data: [], dates: [] },
  cumulativeDownloadsWithDate: { android: 0 },
  cumulativeAppleDownloadsWithDate: { ios: 0 },
  dauWithDate: [],
  dauDates: [],
  mauWithDate: 0,
  appUserCount: 0,
  mongoAppUsers: [],
  mongoSearched: false,
  mongoAppUsersLoading: false,
  mongoAppUsersType: null,
  appUsersDownloadFile: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEST_APP_USERS:
      return {
        ...state,
        testAppUsers: action.payload,
      };
    case GET_APP_USERS:
      return {
        ...state,
        getAppUsersStatus: true,
        appUsers: action.payload.firstMount ? action.payload.data : [...state.appUsers, ...action.payload.data],
      };
    case END_GET_APP_USERS_STATUS:
      return {
        ...state,
        getAppUsersStatus: false,
      };

    case APP_USERS_LOADING:
      return {
        ...state,
        appUsersLoading: true,
      };

    case SET_GET_APP_USERS_STATUS:
      return {
        ...state,
        getAppUsersStatus: action.payload,
      };
    case APP_USER_LOADING:
      return {
        ...state,
        appUserLoading: true,
      };
    case GET_APP_USER:
      return {
        ...state,
        appUserLoading: false,
        appUser: action.payload,
      };
    case EDIT_APP_USER:
      return {
        ...state,
        mongoAppUsers: state.mongoAppUsers.map((appUser) =>
          appUser._id === action.payload._id ? action.payload : appUser,
        ),
      };
    case DELETE_APP_USER:
      return {
        ...state,
        deleteAppUserStatus: true,
        mongoAppUsers: state.mongoAppUsers.filter((user) => {
          return user["_id"] !== action.payload.deletedUserId;
        }),
        // appUsers: action.payload.updatedUsers,
      };
    case DELETE_APP_USER_STATUS:
      return {
        ...state,
        deleteAppUserStatus: false,
      };
    case CLEAR_APP_USER:
      return {
        ...state,
        appUser: {},
      };
    case APP_USER_REGISTER_SUCCESS:
      return {
        ...state,
        mongoAppUsers: [action.payload, ...state.mongoAppUsers],
      };
    case GET_APP_USER_BLOG_POSTING_COMMENTS:
      return {
        ...state,
        appUserBlogPostingComments: action.payload,
      };
    case GET_APP_USER_MAP_POSTING_COMMENTS:
      return {
        ...state,
        appUserMapPostingComments: action.payload,
      };
    case ADD_USERS_SPECIAL_CHARACTERS:
      return {
        ...state,
        addUsersSpecialCharactersStatus: true,
      };
    case ADD_USERS_SPECIAL_CHARACTERS_STATUS:
      return {
        ...state,
        addUsersSpecialCharactersStatus: false,
      };
    case ADD_USERS_POINTS:
      return {
        ...state,
        addUsersPointsStatus: true,
        mongoAppUsers: state.mongoAppUsers.map((appUser) =>
          action.payload.hasOwnProperty(appUser._id) ? action.payload[appUser._id] : appUser,
        ),
        // appUsers: state.appUsers.map((appUser) =>
        //   action.payload.hasOwnProperty(appUser._id) ? { ...appUser, _source: action.payload[appUser._id] } : appUser,
        // ),
      };
    case ADD_USERS_POINTS_STATUS:
      return {
        ...state,
        addUsersPointsStatus: false,
      };
    case CHANGE_USERS_LEVEL:
      return {
        ...state,
        changeUsersLevelStatus: true,
        mongoAppUsers: state.mongoAppUsers.map((appUser) =>
          action.payload.hasOwnProperty(appUser._id) ? action.payload[appUser._id] : appUser,
        ),
        // appUsers: state.appUsers.map((appUser) =>
        //   action.payload.hasOwnProperty(appUser._id) ? { ...appUser, _source: action.payload[appUser._id] } : appUser,
        // ),
      };
    case CHANGE_USERS_LEVEL_STATUS:
      return {
        ...state,
        changeUsersLevelStatus: false,
      };

    case GET_APP_USERS_LEVEL_DISTRIBUTION:
      return {
        ...state,
        appUsersLevelDistribution: action.payload,
      };
    case GET_USER_POINT_HISTORIES_WITH_DATE:
      return {
        ...state,
        userPointHistoriesWithDate: action.payload,
      };
    case SEND_USERS_MESSAGE:
      return {
        ...state,
        sendUsersMessageStatus: true,
      };
    case SEND_USERS_MESSAGES_STATUS:
      return {
        ...state,
        sendUsersMessageStatus: false,
      };
    case SEND_ALL_USERS_MESSAGE:
      return {
        ...state,
        sendAllUsersMessageStatus: true,
        sendAllUsersMessageCount: action.payload.message,
      };
    case SEND_ALL_USERS_MESSAGES_STATUS:
      return {
        ...state,
        sendAllUsersMessageStatus: false,
        sendAllUsersMessageCount: null,
      };

    case GET_DAILY_NEW_USERS_WITH_DATE:
      return {
        ...state,
        dailyNewUsersWithDate: action.payload,
      };
    case GET_CUMULATIVE_USERS_WITH_DATE:
      return {
        ...state,
        cumulativeUsersWithDate: action.payload,
      };
    case GET_CUMULATIVE_DOWNLOADS_WITH_DATE:
      return {
        ...state,
        cumulativeDownloadsWithDate: {
          ...state.cumulativeDownloadsWithDate,
          [action.payload.type]: action.payload.data,
          downloadData: action.payload.downloadData,
        },
      };
    case GET_CUMULATIVE_APPLE_DOWNLOADS_WITH_DATE:
      return {
        ...state,
        cumulativeAppleDownloadsWithDate: {
          ...state.cumulativeAppleDownloadsWithDate,
          [action.payload.type]: action.payload.data,
          downloadData: action.payload.downloadData,
        },
      };
    case SET_DAU_DATES:
      return {
        ...state,
        dauDates: action.payload,
      };
    case GET_DAU_WITH_DATE:
      return {
        ...state,
        dauWithDate: action.payload,
      };
    case GET_MAU_WITH_DATE:
      return {
        ...state,
        mauWithDate: action.payload,
      };
    case GET_APP_USERS_COUNT:
      return {
        ...state,
        appUserCount: action.payload.count,
      };
    case GET_MONGO_APP_USERS:
      return {
        ...state,
        mongoAppUsersLoading: false,
        mongoAppUsersType: "non_initial",
        mongoAppUsers:
          action.payload.type == "initial"
            ? action.payload.foundUsers
            : [...state.mongoAppUsers, ...action.payload.foundUsers],
        appUserCount: action.payload.count, // state.mongoSearched ? action.payload.count : state.appUserCount,
      };
    case SET_MONGO_SEARCHED:
      return {
        ...state,
        mongoSearched: action.payload,
      };
    case SET_MONGO_APP_USERS_TYPE:
      return {
        ...state,
        mongoAppUsersType: action.payload,
      };
    case SET_MONGO_APP_USERS_LOADING:
      return {
        ...state,
        mongoAppUsersLoading: action.payload,
      };
    case GET_APP_USERS_DOWNLOAD_FILE:
      return {
        ...state,
        appUsersDownloadFile: action.payload,
      };

    default:
      return state;
  }
}
