import { actions } from "react-table";
import {
  NOTICE_POSTINGS_LOADING,
  SET_GET_NOTICE_POSTINGS_STATUS,
  GET_NOTICE_POSTINGS,
  ADD_NOTICE_POSTING,
  ADD_NOTICE_POSTING_STATUS,
  EDIT_NOTICE_POSTING,
  EDIT_NOTICE_POSTING_STATUS,
  DELETE_NOTICE_POSTING,
  DELETE_NOTICE_POSTING_STATUS,
  GET_NOTICE_CATEGORY_LIST,
  SET_CATEGORY_LIST,
} from "../actions/types";

const initialState = {
  noticePostingsLoading: false,
  noticePostings: [],
  editNoticePostingStatus: false,
  deleteNoticePostingStatus: false,
  getNoticePostingsStatus: false,
  noticeCategoryList : [],
  categoryList : [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTICE_POSTINGS_LOADING:
      return {
        ...state,
        noticePostingsLoading: true,
      };
    case GET_NOTICE_POSTINGS:
      return {
        ...state,
        noticePostingsLoading: false,
        getNoticePostingsStatus: false,
        noticePostings: action.payload,
      };
    case SET_GET_NOTICE_POSTINGS_STATUS:
      return {
        ...state,
        getNoticePostingsStatus: action.payload,
      };
    case ADD_NOTICE_POSTING:
      return {
        ...state,
        addNoticePostingStatus: true,
        noticePostings: [action.payload, ...state.noticePostings],
      };
    case ADD_NOTICE_POSTING_STATUS:
      return {
        ...state,
        addNoticePostingStatus: false,
      };
    case EDIT_NOTICE_POSTING:
      return {
        ...state,
        editNoticePostingStatus: true,
        noticePostings: state.noticePostings.map((posting) => {
          if (posting["_id"] === action.payload["_id"]) {
            return action.payload;
          } else {
            return posting;
          }
        }),
      };
    case EDIT_NOTICE_POSTING_STATUS:
      return {
        ...state,
        editNoticePostingStatus: false,
      };
    case DELETE_NOTICE_POSTING:
      return {
        ...state,
        deleteNoticePostingStatus: true,
        noticePostings: state.noticePostings.filter((posting) => {
          return posting["_id"] !== action.payload;
        }),
      };
    case DELETE_NOTICE_POSTING_STATUS:
      return {
        ...state,
        deleteNoticePostingStatus: false,
      };
    case GET_NOTICE_CATEGORY_LIST :
      return {
        ...state,
        noticeCategoryList : action.payload,
      }
    case SET_CATEGORY_LIST :
      return {
        ...state,
        categoryList : action.payload
      }

    default:
      return state;
  }
}
