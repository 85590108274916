import {
  SET_BLOCK_HISTORY,
  ERROR_GET_BLOCK_HISTORY,
  SET_BLOCK_HISTORY_PROCESS,
  SET_BLOCK_HISTORY_CURRENT_PAGE,
  SET_BLOCK_HISTORY_SEARCH_OBJ,
} from "../actions/types";

const initialState = {
  blockHistoryList: [],
  blockHistoryProcess: false,
  blockHistoryCount: 0,
  searchObj: {
    username: "",
    targetusername: "",
    search_status: "",
  },
  currentPage: 0,
  maxPage: 1,
  changeStatus: false,
  initial: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BLOCK_HISTORY:
      return {
        ...state,
        blockHistoryList: action.payload.type
          ? action.payload.data
          : [...state.blockHistoryList, ...action.payload.data],
        blockHistoryCount: action.payload.count,
        initial: false,
        blockHistoryProcess: false,
      };

    case SET_BLOCK_HISTORY_PROCESS:
      return {
        ...state,
        blockHistoryProcess: action.payload,
      };

    case SET_BLOCK_HISTORY_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_BLOCK_HISTORY_SEARCH_OBJ:
      return {
        ...state,
        searchObj: action.payload,
      };
    case ERROR_GET_BLOCK_HISTORY:
      return {
        ...state,
      };

    default:
      return state;
  }
}
