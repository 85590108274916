import {
  GET_REPORT,
  SET_REPORT_LIST,
  CHANGE_REPORTS_STATUS,
  CHANGE_REPORTS_STATUS_STATE,
  DELETE_REPORTS,
  DELETE_REPORTS_STATUS,
  SET_REPORT_PROCESS,
  SET_REPORT_CURRENT_PAGE,
  SET_REPORT_SEARCH_OBJ,
  SET_REPORT_MAX_PAGE,
  CHANGE_CURRENT_PUSH_STATE_REPORT,
} from "../actions/types";

const initialState = {
  report: {},
  reportList: [],
  reportProcess: false,
  reportCount: 0,
  searchObj: {
    reportStatus: 0,
    reportType: "ALL",
    reportBody: "",
    reportUser: "",
    user: "",
    reportLevelUnderZero: false,
  },
  currentPage: 0,
  maxPage: 50,
  changeReportStatus: false,
  deleteReportStatus: false,
  initial: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REPORT:
      return {
        ...state,
        report: action.payload,
      };

    case CHANGE_CURRENT_PUSH_STATE_REPORT:
      return {
        ...state,
        reportCount: state.reportCount - action.payload.ids.length + action.payload.moreItems.length,
        reportList: [
          ...state.reportList
            .filter((item) => {
              if (action.payload.ids.includes(item._id)) {
                return false;
              } else {
                return true;
              }
            })
            .sort((a, b) => a.createdAt - b.createdAt),
          ...action.payload.moreItems,
        ],
      };
    case SET_REPORT_PROCESS:
      return {
        ...state,
        reportProcess: action.payload,
      };

    case SET_REPORT_LIST:
      return {
        ...state,
        reportList: action.payload.type ? action.payload.data : [...state.reportList, ...action.payload.data],
        reportCount: action.payload.count,
        initial: false,
        reportProcess: false,
      };

    case SET_REPORT_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_REPORT_SEARCH_OBJ:
      return {
        ...state,
        searchObj: action.payload,
      };
    case SET_REPORT_MAX_PAGE:
      return {
        ...state,
        maxPage: action.payload,
      };

    case CHANGE_REPORTS_STATUS:
      const tempL = [...state.reportList];
      tempL.map((l) => {
        if (l.reported_user != null) {
          if (action.payload.reportedUserIds.includes(l.reported_user._id)) {
            l.is_approved = true;
          }
        }
      });
      return {
        ...state,
        changeReportStatus: true,
        reportList: tempL,
      };
    case CHANGE_REPORTS_STATUS_STATE:
      return {
        ...state,
        changeReportStatus: false,
      };

    case DELETE_REPORTS:
      let tempQDLA = [
        ...state.reportList.filter((l) => !action.payload.deletedIds.includes(l["_id"])),
        ...action.payload.afterItem,
      ];
      tempQDLA = tempQDLA.sort((a, b) => a.createdAt - b.createdAt);

      return {
        ...state,
        deleteReportStatus: true,
        reportList: tempQDLA,
      };

    case DELETE_REPORTS_STATUS:
      return {
        ...state,
        deleteReportStatus: false,
      };
    default:
      return state;
  }
}
