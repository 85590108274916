import React from "react";
import { Layout, Menu } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppHeader from "./appHeader";
import "../stylesheets/layoutBuilder.scss";
import { RobotFilled } from "@ant-design/icons";
const { Content, Sider, Header } = Layout;

const LayoutBuilderBeta = ({ router }) => {
  const adminState = useSelector((state) => state.auth);
  const sider = () => {
    return (
      <Sider
        width={200}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]} mode="inline">
          <p className="itemTitleBeta">온달봇</p>
          <Menu.Item key="/ondalbot" className="itemMenu">
            <Link to="/ondalbot">
              <RobotFilled className="ondalbotTitle" />
              온달봇 베타
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  };
  return adminState.isAuthenticated ? (
    <div className="appLayout">
      <Layout>
        {sider()}
        <Layout
          className="nestedLayout"
          width={200}
          style={{
            overflow: "auto",
            height: "100vh",
            width: "calc(100% - 200px)",
            position: "fixed",
            left: 200,
          }}
        >
          <AppHeader />
          <Content className="appContent">{router}</Content>
        </Layout>
      </Layout>
    </div>
  ) : (
    <>{router}</>
  );
};

export default LayoutBuilderBeta;
